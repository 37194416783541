import { useMemo, memo, useCallback } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import LazyImage from '../components/shared/LazyImage';
import FadeIn from '../components/shared/FadeIn';
import { 
  CalendarIcon, ArrowTopRightOnSquareIcon,
  ChartBarIcon, NewspaperIcon, GlobeAltIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';

const NewsCard = memo(({ article, index }) => (
  <FadeIn delay={index * 0.1}>
    <div className="bg-white rounded-xl shadow-lg overflow-hidden group cursor-pointer">
      <div className="h-48 relative overflow-hidden">
        <LazyImage 
          src={article.image}
          alt={article.title}
          className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-500"
        />
        <div className="absolute top-4 left-4">
          <span className="inline-block bg-white text-[#891F12] px-3 py-1 rounded-full text-sm font-medium">
            {article.category}
          </span>
        </div>
      </div>
      <div className="p-6">
        <h3 className="text-xl font-bold text-gray-900 mb-2">{article.title}</h3>
        <p className="text-gray-600 mb-4">{article.description}</p>
        <div className="flex items-center justify-between text-sm text-gray-500">
          <div className="flex items-center">
            <CalendarIcon className="h-5 w-5 mr-2" />
            <span>{article.date}</span>
          </div>
          <span>{article.readTime}</span>
        </div>
      </div>
    </div>
  </FadeIn>
));

const MarketUpdateCard = memo(({ update, index }) => (
  <FadeIn delay={index * 0.1}>
    <div className="bg-white p-6 rounded-xl shadow-lg">
      <h3 className="text-lg font-bold text-gray-900 mb-2">{update.title}</h3>
      <p className="text-sm text-gray-500 mb-4">{update.date}</p>
      <div className="space-y-3">
        {Object.entries(update.stats).map(([key, value]) => (
          <div key={key} className="flex justify-between items-center">
            <span className="text-gray-600 capitalize">{key}</span>
            <span className="font-medium text-[#891F12]">{value}</span>
          </div>
        ))}
      </div>
    </div>
  </FadeIn>
));

function News() {
  const navigate = useNavigate();

  const handleNavigation = useCallback((path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [navigate]);

  const featuredNews = useMemo(() => ({
    title: "Global Art Market Reaches Record $95.2 Billion in 2023",
    date: "October 15, 2024",
    category: "Market Analysis",
    image: "https://images.pexels.com/photos/1647121/pexels-photo-1647121.jpeg",
    description: "The global art market has achieved unprecedented growth, reaching $95.2 billion in total sales for 2023, marking a 17% increase from the previous year. Digital art sales and emerging market participation have been key drivers of this growth.",
    author: "Financial Art Review",
    readTime: "8 min read",
    highlights: [
      "17% year-over-year market growth",
      "Digital art sector expansion",
      "Emerging markets contribution",
      "Record-breaking auctions"
    ]
  }), []);

  const latestNews = useMemo(() => [
    {
      title: "Contemporary Australian Art Market Shows Strong Growth Potential",
      date: "October 12, 2024",
      category: "Market Trends",
      image: "/images/0093deaf-aece-43b7-8f38-0d67eed42227.jpeg",
      description: "Australian contemporary art continues to gain international recognition, with sales increasing by 44% in 2023. Collectors and institutions are showing growing interest in artists from Sydney and Melbourne.",
      author: "Art Market Insights",
      readTime: "6 min read"
    },
    {
      title: "Digital Art Authentication: Blockchain Technology Revolutionizes Provenance",
      date: "August 10, 2024",
      category: "Technology",
      image: "/images/e341bda0-7106-452a-b616-3756b4345a0a.jpeg",
      description: "New blockchain-based authentication systems are transforming how artwork provenance is verified and recorded, providing unprecedented security and transparency in art transactions.",
      author: "Tech Art Journal",
      readTime: "7 min read"
    },
    {
      title: "Major Museums Adopt AI for Collection Management",
      date: "August 8, 2024",
      category: "Innovation",
      image: "/images/Top 20 Most Famous Paintings by Rene Magritte - Iconic Artworks _ Berühmte kunst gemälde, Berühmte kunst, Surrealismus malerei.jpeg",
      description: "Leading museums worldwide are implementing AI-powered systems for better collection management and visitor experience enhancement.",
      author: "Museum Technology Review",
      readTime: "5 min read"
    },
    {
      title: "Art Investment Funds See Record Inflows in Q4 2023",
      date: "August 5, 2024",
      category: "Investment",
      image: "/images/How Watercolor Can Make You Lose Friends - OutdoorPainter.jpeg",
      description: "Art investment funds have reported unprecedented capital inflows in Q4 2023, with total assets under management exceeding $12 billion globally.",
      author: "Investment Weekly",
      readTime: "6 min read"
    },
    {
      title: "Emerging Artists to Watch in 2024",
      date: "August 1, 2024",
      category: "Artist Spotlight",
      image: "/images/0093deaf-aece-43b7-8f38-0d67eed42227.jpeg",
      description: "Our annual selection of emerging artists showing exceptional promise and investment potential for the coming year.",
      author: "Art Investment Review",
      readTime: "10 min read"
    }
  ], []);

  const marketUpdates = useMemo(() => [
    {
      title: "Q4 2023 Art Market Performance",
      date: "November 14, 2024",
      stats: {
        growth: "+12.5%",
        transactions: "15,234",
        averageValue: "£125,000"
      }
    },
    {
      title: "Contemporary Art Index",
      date: "November 13, 2024",
      stats: {
        growth: "+8.7%",
        topSale: "£12.5M",
        newArtists: "234"
      }
    },
    {
      title: "Digital Art Sector",
      date: "October 22, 2024",
      stats: {
        growth: "+22.3%",
        platforms: "45",
        totalVolume: "£890M"
      }
    }
  ], []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <section className="relative h-[60vh] bg-[#891F12] overflow-hidden">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.1 }}
          className="absolute inset-0 grid grid-cols-6 grid-rows-6 gap-1"
        >
          {[...Array(36)].map((_, i) => (
            <div key={i} className="bg-white/10 rounded-lg" />
          ))}
        </motion.div>
        <div className="relative h-full flex items-center justify-center text-center z-10">
          <div className="text-white px-4">
            <h1 className="text-5xl font-bold mb-6">Latest News</h1>
            <p className="text-xl text-gray-200 max-w-2xl mx-auto">
              Stay informed with the latest updates from the art investment world
            </p>
          </div>
        </div>
      </section>

      {/* Featured Article */}
      <section className="relative z-10 max-w-7xl mx-auto px-4 -mt-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-xl shadow-xl overflow-hidden"
        >
          <div className="md:flex">
            <div className="md:w-1/2">
              <img 
                src={featuredNews.image}
                alt={featuredNews.title}
                className="h-full w-full object-cover"
              />
            </div>
            <div className="md:w-1/2 p-8">
              <span className="inline-block bg-[#891F12]/10 text-[#891F12] px-3 py-1 rounded-full text-sm font-medium mb-4">
                {featuredNews.category}
              </span>
              <h2 className="text-2xl font-bold text-gray-900 mb-4">{featuredNews.title}</h2>
              <p className="text-gray-600 mb-6">{featuredNews.description}</p>
              <div className="flex items-center text-gray-500 text-sm mb-6">
                <CalendarIcon className="h-5 w-5 mr-2" />
                <span>{featuredNews.date}</span>
                <span className="mx-2">•</span>
                <span>{featuredNews.readTime}</span>
              </div>
              <div className="space-y-2 mb-6">
                {featuredNews.highlights.map((highlight, idx) => (
                  <div key={idx} className="flex items-center text-sm text-gray-600">
                    <span className="h-1.5 w-1.5 bg-[#891F12] rounded-full mr-2" />
                    {highlight}
                  </div>
                ))}
              </div>
              <button className="text-[#891F12] font-medium flex items-center hover:underline">
                Read More
                <ArrowTopRightOnSquareIcon className="h-5 w-5 ml-1" />
              </button>
            </div>
          </div>
        </motion.div>
      </section>

      {/* Market Updates */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <div className="grid md:grid-cols-3 gap-8">
          {marketUpdates.map((update, index) => (
            <MarketUpdateCard key={index} update={update} index={index} />
          ))}
        </div>
      </section>

      {/* Latest News Grid */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <h2 className="text-3xl font-bold text-gray-900 mb-12">Latest Articles</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {latestNews.map((article, index) => (
            <NewsCard key={index} article={article} index={index} />
          ))}
        </div>
      </section>

      {/* Newsletter CTA */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-[#891F12] text-white rounded-xl p-12 text-center"
        >
          <h2 className="text-3xl font-bold mb-4">Stay Informed</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Subscribe to our newsletter for the latest art market insights and investment opportunities.
          </p>
          <button 
            onClick={() => handleNavigation('/contact')}
            className="bg-white text-[#891F12] px-8 py-3 rounded-lg hover:bg-gray-100 
              transition-colors font-medium inline-flex items-center space-x-2"
          >
            <span>Subscribe Now</span>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-5 w-5" 
              viewBox="0 0 20 20" 
              fill="currentColor"
            >
              <path 
                fillRule="evenodd" 
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" 
                clipRule="evenodd" 
              />
            </svg>
          </button>
        </motion.div>
      </section>
    </div>
  );
}

export default News;
