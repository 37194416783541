import { useMemo, memo, useCallback } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import LazyImage from '../components/shared/LazyImage';
import FadeIn from '../components/shared/FadeIn';
import { 
  CalendarIcon, MapPinIcon, UserGroupIcon, 
  AcademicCapIcon, SparklesIcon, GlobeAltIcon 
} from '@heroicons/react/24/outline';

const EventCard = memo(({ event, index, handleNavigation }) => (
  <FadeIn delay={index * 0.1}>
    <div className="bg-white rounded-xl shadow-lg overflow-hidden group">
      <div className="h-64 relative overflow-hidden">
        <LazyImage 
          src={event.image}
          alt={event.title}
          className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-500"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
        <div className="absolute bottom-4 left-4 text-white">
          <span className="inline-block bg-[#891F12] px-3 py-1 rounded-full text-sm font-medium">
            {event.type}
          </span>
        </div>
      </div>
      <div className="p-6">
        <h3 className="text-xl font-bold text-gray-900 mb-2">{event.title}</h3>
        <div className="flex items-center text-gray-600 mb-2">
          <CalendarIcon className="h-5 w-5 mr-2" />
          <span>{event.date}</span>
        </div>
        <div className="flex items-center text-gray-600 mb-4">
          <MapPinIcon className="h-5 w-5 mr-2" />
          <span>{event.location}</span>
        </div>
        <p className="text-gray-600 mb-4">{event.description}</p>
        <div className="space-y-2">
          {event.highlights.map((highlight, idx) => (
            <div key={idx} className="flex items-center text-sm text-gray-600">
              <span className="h-1.5 w-1.5 bg-[#891F12] rounded-full mr-2" />
              {highlight}
            </div>
          ))}
        </div>
        <button
          onClick={() => handleNavigation('/contact')}
          className="mt-6 w-full bg-[#891F12] text-white px-4 py-2 rounded-lg 
            hover:bg-[#6F1A0E] transition-colors flex items-center justify-center space-x-2"
        >
          <span>Register Interest</span>
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-5 w-5" 
            viewBox="0 0 20 20" 
            fill="currentColor"
          >
            <path 
              fillRule="evenodd" 
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" 
              clipRule="evenodd" 
            />
          </svg>
        </button>
      </div>
    </div>
  </FadeIn>
));

function Events() {
  const navigate = useNavigate();

  const handleNavigation = useCallback((path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [navigate]);

  const upcomingEvents = useMemo(() => [
    {
      title: "Art Investment Masterclass",
      date: "January 15, 2024",
      location: "London, UK",
      type: "Seminar",
      description: "Join our expert panel for an in-depth discussion on contemporary art market trends and investment strategies. Perfect for both new and experienced collectors.",
      image: "https://images.pexels.com/photos/2774556/pexels-photo-2774556.jpeg",
      highlights: [
        "Market analysis workshop",
        "Portfolio diversification strategies",
        "Networking reception",
        "One-on-one consultation opportunities"
      ]
    },
    {
      title: "Emerging Artists Showcase",
      date: "February 28, 2024",
      location: "New York, USA",
      type: "Exhibition",
      description: "Discover the next generation of artistic talent. This curated exhibition features works from rising stars in the contemporary art scene.",
      image: "https://images.pexels.com/photos/1509534/pexels-photo-1509534.jpeg",
      highlights: [
        "Meet the artists",
        "Early acquisition opportunities",
        "Curator's tour",
        "VIP preview evening"
      ]
    },
    {
      title: "Digital Art & NFT Summit",
      date: "March 20, 2024",
      location: "Dubai, UAE",
      type: "Conference",
      description: "Explore the intersection of traditional art investment and digital innovations. Learn about NFTs, blockchain, and the future of art ownership.",
      image: "https://images.pexels.com/photos/2747449/pexels-photo-2747449.jpeg",
      highlights: [
        "Blockchain technology overview",
        "NFT investment strategies",
        "Digital art marketplace insights",
        "Tech demonstrations"
      ]
    },
    {
      title: "Art Basel Hong Kong",
      date: "May 15-18, 2024",
      location: "Hong Kong",
      type: "Art Fair",
      description: "Join us at Asia's premier international art fair. Exclusive access to private viewings and VIP events for our clients.",
      image: "https://images.pexels.com/photos/1647121/pexels-photo-1647121.jpeg",
      highlights: [
        "VIP preview access",
        "Guided tours",
        "Collector's dinner",
        "Artist meet-and-greets"
      ]
    },
    {
      title: "Modern Masters Exhibition",
      date: "July 10, 2024",
      location: "Paris, France",
      type: "Exhibition",
      description: "A carefully curated selection of 20th-century masterpieces, showcasing investment-grade artworks from renowned artists.",
      image: "https://images.pexels.com/photos/1674049/pexels-photo-1674049.jpeg",
      highlights: [
        "Expert-led tours",
        "Investment analysis presentations",
        "Authentication workshops",
        "Private viewing sessions"
      ]
    },
    {
      title: "Asian Art Market Symposium",
      date: "September 5, 2024",
      location: "Singapore",
      type: "Conference",
      description: "Comprehensive overview of the Asian art market, featuring leading experts and market analysts.",
      image: "https://images.pexels.com/photos/5430783/pexels-photo-5430783.jpeg",
      highlights: [
        "Market trend analysis",
        "Regional focus sessions",
        "Networking opportunities",
        "Investment strategy workshops"
      ]
    },
    {
      title: "Contemporary Art Investment Forum",
      date: "November 20, 2024",
      location: "Miami, USA",
      type: "Conference",
      description: "Coinciding with Art Basel Miami, this forum brings together leading investors and art market experts.",
      image: "https://images.pexels.com/photos/1647121/pexels-photo-1647121.jpeg",
      highlights: [
        "Market outlook 2025",
        "Investment panel discussions",
        "Art Basel VIP access",
        "Exclusive networking events"
      ]
    },
    {
      title: "Future of Art Investment Summit",
      date: "January 25, 2025",
      location: "London, UK",
      type: "Conference",
      description: "A forward-looking conference exploring emerging trends and opportunities in art investment.",
      image: "https://images.pexels.com/photos/1509534/pexels-photo-1509534.jpeg",
      highlights: [
        "2025 market predictions",
        "Technology in art investment",
        "Sustainability in art",
        "Next-gen collector insights"
      ]
    },
    {
      title: "Global Art Investment Expo",
      date: "March 15, 2025",
      location: "Tokyo, Japan",
      type: "Exhibition",
      description: "Our flagship event bringing together international galleries, collectors, and investment opportunities.",
      image: "https://images.pexels.com/photos/2747449/pexels-photo-2747449.jpeg",
      highlights: [
        "International gallery showcase",
        "Investment seminars",
        "Cultural exchange programs",
        "Market analysis presentations"
      ]
    }
  ], []);

  const eventTypes = useMemo(() => [
    {
      type: "Seminars",
      icon: AcademicCapIcon,
      description: "Expert-led educational sessions"
    },
    {
      type: "Exhibitions",
      icon: SparklesIcon,
      description: "Curated art showcases"
    },
    {
      type: "Conferences",
      icon: UserGroupIcon,
      description: "Industry networking events"
    },
    {
      type: "Art Fairs",
      icon: GlobeAltIcon,
      description: "International art markets"
    }
  ], []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <section className="relative h-[60vh] bg-[#891F12] overflow-hidden">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 0.1 }}
          viewport={{ once: true }}
          className="absolute inset-0 grid grid-cols-6 grid-rows-6 gap-1"
        >
          {useMemo(() => (
            [...Array(36)].map((_, i) => (
              <div key={i} className="bg-white/10 rounded-lg" />
            ))
          ), [])}
        </motion.div>
        
        <FadeIn className="relative h-full flex items-center justify-center text-center z-10">
          <div className="text-white px-4">
            <h1 className="text-5xl font-bold mb-6">Upcoming Events</h1>
            <p className="text-xl text-gray-200 max-w-2xl mx-auto">
              Join us at exclusive art investment events around the world
            </p>
          </div>
        </FadeIn>
      </section>

      {/* Event Types */}
      <section className="relative z-10 max-w-7xl mx-auto px-4 -mt-16">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-xl shadow-xl p-8 grid grid-cols-1 md:grid-cols-4 gap-8"
        >
          {eventTypes.map((type, index) => (
            <div key={index} className="text-center">
              <div className="bg-[#891F12]/10 p-4 rounded-full w-16 h-16 mx-auto mb-4">
                <type.icon className="h-8 w-8 text-[#891F12]" />
              </div>
              <h3 className="font-bold text-gray-900 mb-2">{type.type}</h3>
              <p className="text-sm text-gray-600">{type.description}</p>
            </div>
          ))}
        </motion.div>
      </section>

      {/* Events Grid */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <div className="grid md:grid-cols-2 gap-8">
          {upcomingEvents.map((event, index) => (
            <EventCard 
              key={index} 
              event={event} 
              index={index} 
              handleNavigation={handleNavigation}
            />
          ))}
        </div>
      </section>

      {/* CTA Section */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-[#891F12] text-white rounded-xl p-12 text-center"
        >
          <h2 className="text-3xl font-bold mb-4">Don't Miss Out</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Register your interest to receive exclusive invitations and early access to our events.
          </p>
          <button 
            onClick={() => handleNavigation('/contact')}
            className="bg-white text-[#891F12] px-8 py-3 rounded-lg hover:bg-gray-100 
              transition-colors font-medium inline-flex items-center space-x-2"
          >
            <span>Contact Us</span>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-5 w-5" 
              viewBox="0 0 20 20" 
              fill="currentColor"
            >
              <path 
                fillRule="evenodd" 
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" 
                clipRule="evenodd" 
              />
            </svg>
          </button>
        </motion.div>
      </section>
    </div>
  );
}

export default memo(Events);
