import { useMemo, memo } from 'react';
import { motion } from 'framer-motion';
import LazyImage from '../components/shared/LazyImage';
import FadeIn from '../components/shared/FadeIn';
import { 
  ChartBarIcon, 
  BuildingLibraryIcon,
  PresentationChartLineIcon,
  UserGroupIcon,
  SparklesIcon,
  ArrowTrendingUpIcon,
  GlobeAltIcon,
  DocumentChartBarIcon
} from '@heroicons/react/24/outline';

// Move these outside component to prevent recreating on each render
const GridBackground = memo(() => (
  <motion.div
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 0.1 }}
    viewport={{ once: true }}
    className="absolute inset-0 grid grid-cols-8 grid-rows-8 gap-1"
  >
    {[...Array(64)].map((_, i) => (
      <div key={i} className="bg-white/10 rounded-lg" />
    ))}
  </motion.div>
));

const ServiceCard = memo(({ service, index }) => (
  <FadeIn delay={index * 0.1}>
    <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
      <service.icon className="h-12 w-12 text-[#891F12] mb-6" />
      <h3 className="text-2xl font-bold text-gray-900 mb-4">{service.title}</h3>
      <p className="text-gray-600 mb-6">{service.description}</p>
      <ul className="space-y-3">
        {service.features.map((feature, idx) => (
          <li key={idx} className="flex items-center text-gray-600">
            <span className="h-2 w-2 bg-[#891F12] rounded-full mr-3" />
            {feature}
          </li>
        ))}
      </ul>
    </div>
  </FadeIn>
));

function WhatWeDo() {
  // Use useMemo for static data
  const services = useMemo(() => [
    {
      icon: ChartBarIcon,
      title: "Art Investment Advisory",
      description: "Expert guidance on building and managing art portfolios for optimal returns",
      features: [
        "Market analysis and trend forecasting",
        "Portfolio diversification strategies",
        "Risk assessment and management",
        "Investment performance tracking"
      ]
    },
    {
      icon: BuildingLibraryIcon,
      title: "Collection Management",
      description: "Comprehensive care and administration of art collections",
      features: [
        "Inventory management and documentation",
        "Conservation and restoration",
        "Insurance and security solutions",
        "Digital collection management"
      ]
    },
    {
      icon: PresentationChartLineIcon,
      title: "Market Intelligence",
      description: "In-depth art market research and analytics",
      features: [
        "Price trend analysis",
        "Artist performance tracking",
        "Market opportunity identification",
        "Auction result analysis"
      ]
    },
    {
      icon: UserGroupIcon,
      title: "Private Client Services",
      description: "Personalized art acquisition and sales support",
      features: [
        "Artwork sourcing and acquisition",
        "Sale and exit strategy planning",
        "Private treaty sales",
        "Auction representation"
      ]
    }
  ], []);

  const process = [
    {
      number: "01",
      title: "Initial Consultation",
      description: "We begin with a detailed discussion of your investment goals, preferences, and budget."
    },
    {
      number: "02",
      title: "Strategy Development",
      description: "Our team creates a personalized investment strategy aligned with your objectives."
    },
    {
      number: "03",
      title: "Portfolio Building",
      description: "We help you acquire carefully selected artworks to build a balanced portfolio."
    },
    {
      number: "04",
      title: "Ongoing Management",
      description: "Regular portfolio reviews and adjustments to optimize performance."
    }
  ];

  const stats = [
    { number: "£50M+", label: "Assets Under Management" },
    { number: "15+", label: "Years Experience" },
    { number: "300+", label: "Active Portfolios" },
    { number: "25%", label: "Average Annual Return" }
  ];

  const expertise = useMemo(() => [
    {
      title: "Market Analysis",
      description: "Deep dive into art market trends and opportunities",
      image: "https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80",
      stats: ["15+ Years Experience", "200+ Market Reports", "Global Coverage"]
    },
    {
      title: "Portfolio Management",
      description: "Strategic art portfolio building and optimization",
      image: "https://images.unsplash.com/photo-1526304640581-d334cdbbf45e?q=80",
      stats: ["£50M+ Managed", "300+ Portfolios", "25% Avg. Returns"]
    },
    {
      title: "Artist Discovery",
      description: "Identifying emerging artists and investment opportunities",
      image: "https://images.unsplash.com/photo-1513364776144-60967b0f800f?q=80",
      stats: ["100+ Artists", "30+ Countries", "Rising Stars"]
    }
  ], []);

  const benefits = [
    {
      icon: SparklesIcon,
      title: "Expert Curation",
      description: "Access to carefully selected artworks with strong investment potential"
    },
    {
      icon: ArrowTrendingUpIcon,
      title: "Performance Tracking",
      description: "Regular portfolio valuation and performance analytics"
    },
    {
      icon: GlobeAltIcon,
      title: "Global Access",
      description: "Connect with international art markets and opportunities"
    },
    {
      icon: DocumentChartBarIcon,
      title: "Market Reports",
      description: "Detailed analysis and insights on art market trends"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <section className="relative h-[60vh] bg-[#891F12] overflow-hidden">
        <GridBackground />
        <FadeIn className="relative h-full flex items-center justify-center text-center z-10">
          <div className="text-white px-4">
            <h1 className="text-5xl font-bold mb-4">What We Do</h1>
            <p className="text-xl text-gray-200 max-w-2xl mx-auto">
              Expert art investment services for discerning collectors
            </p>
          </div>
        </FadeIn>
      </section>

      {/* Stats Section */}
      <section className="relative z-10 max-w-7xl mx-auto px-4 -mt-16">
        <FadeIn delay={0} className="grid grid-cols-2 md:grid-cols-4 gap-4 bg-white rounded-xl shadow-xl p-8">
          {stats.map((stat, index) => (
            <div key={index} className="text-center">
              <h3 className="text-3xl font-bold text-[#891F12]">{stat.number}</h3>
              <p className="text-gray-600">{stat.label}</p>
            </div>
          ))}
        </FadeIn>
      </section>

      {/* Services Section */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <FadeIn className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Services</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Comprehensive art investment solutions tailored to your needs
          </p>
        </FadeIn>

        <div className="grid md:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} service={service} index={index} />
          ))}
        </div>
      </section>

      {/* Process Section */}
      <section className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4">
          <FadeIn className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Process</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              A structured approach to art investment
            </p>
          </FadeIn>

          <div className="grid md:grid-cols-4 gap-8">
            {process.map((step, index) => (
              <FadeIn key={index} delay={index * 0.1}>
                <div className="bg-white p-8 rounded-xl shadow-lg relative">
                  <span className="text-4xl font-bold text-[#891F12]/10 absolute top-4 right-4">
                    {step.number}
                  </span>
                  <h3 className="text-xl font-bold text-gray-900 mb-4">{step.title}</h3>
                  <p className="text-gray-600">{step.description}</p>
                </div>
              </FadeIn>
            ))}
          </div>
        </div>
      </section>

      {/* Expertise Section */}
      <section className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4">
          <FadeIn className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Expertise</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Combining art knowledge with investment acumen
            </p>
          </FadeIn>

          <div className="grid md:grid-cols-3 gap-8">
            {expertise.map((item, index) => (
              <FadeIn key={index} delay={index * 0.1}>
                <div className="group relative overflow-hidden rounded-xl shadow-lg">
                  <LazyImage 
                    src={item.image} 
                    alt={item.title}
                    className="object-cover w-full h-full group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent" />
                  <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
                    <h3 className="text-xl font-bold mb-2">{item.title}</h3>
                    <p className="text-gray-200 mb-4">{item.description}</p>
                    <div className="flex flex-wrap gap-2">
                      {item.stats.map((stat, idx) => (
                        <span 
                          key={idx}
                          className="bg-white/20 backdrop-blur-sm px-3 py-1 rounded-full text-sm"
                        >
                          {stat}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </FadeIn>
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Grid with Graphics */}
      <section className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4">
          <FadeIn className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Why Choose Us</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Experience the advantages of our comprehensive approach
            </p>
          </FadeIn>

          <div className="grid md:grid-cols-2 gap-8">
            {benefits.map((benefit, index) => (
              <FadeIn key={index} delay={index * 0.1}>
                <div className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-shadow">
                  <div className="flex items-start">
                    <div className="bg-[#891F12]/10 p-3 rounded-lg">
                      <benefit.icon className="h-8 w-8 text-[#891F12]" />
                    </div>
                    <div className="ml-6">
                      <h3 className="text-xl font-bold text-gray-900 mb-2">{benefit.title}</h3>
                      <p className="text-gray-600">{benefit.description}</p>
                    </div>
                  </div>
                </div>
              </FadeIn>
            ))}
          </div>
        </div>
      </section>

      {/* Interactive Gallery Preview */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <FadeIn className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">
              Discover Our Latest Acquisitions
            </h2>
            <p className="text-gray-600 mb-8">
              Preview some of our recent additions to our carefully curated collection
              of investment-grade artworks.
            </p>
            <div className="space-y-4">
              {["Contemporary Masters", "Emerging Artists", "Limited Editions"].map((item, index) => (
                <div key={index} className="flex items-center space-x-3">
                  <span className="h-2 w-2 bg-[#891F12] rounded-full" />
                  <span className="text-gray-700">{item}</span>
                </div>
              ))}
            </div>
            <button className="mt-8 bg-[#891F12] text-white px-8 py-3 rounded-lg hover:bg-[#761A0F] transition-colors">
              View Gallery
            </button>
          </FadeIn>
          
          <FadeIn className="grid grid-cols-2 gap-4">
            {[
              "https://images.pexels.com/photos/1616403/pexels-photo-1616403.jpeg", // Abstract contemporary art
              "https://images.pexels.com/photos/1839919/pexels-photo-1839919.jpeg", // Modern gallery space
              "https://images.pexels.com/photos/2570059/pexels-photo-2570059.jpeg", // Contemporary sculpture
              "https://images.pexels.com/photos/1674049/pexels-photo-1674049.jpeg"  // Modern painting
            ].map((image, index) => (
              <FadeIn key={index} delay={index * 0.1}>
                <div className="aspect-square overflow-hidden rounded-lg shadow-lg group">
                  <LazyImage 
                    src={image} 
                    alt={`Gallery preview ${index + 1}`}
                    className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-500"
                  />
                </div>
              </FadeIn>
            ))}
          </FadeIn>
        </div>
      </section>

      {/* CTA Section */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <FadeIn className="bg-[#891F12] text-white rounded-xl p-12 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to Start Your Art Investment Journey?</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Let our experts guide you through the process of building a valuable art portfolio
          </p>
          <button className="bg-white text-[#891F12] px-8 py-3 rounded-lg hover:bg-gray-100 transition-colors font-medium">
            Schedule a Consultation
          </button>
        </FadeIn>
      </section>
    </div>
  );
}

export default memo(WhatWeDo);
