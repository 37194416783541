export const articles = {
  'global-art-market-2023': {
    id: 'global-art-market-2023',
    title: "Global Art Market Reaches Record $95.2 Billion in 2023",
    date: "December 15, 2023",
    author: "Sarah Mitchell",
    readTime: "8 min read",
    category: "Market Analysis",
    image: "https://images.pexels.com/photos/1647121/pexels-photo-1647121.jpeg",
    // ... content as before
  },
  'australian-art-market-growth': {
    id: 'australian-art-market-growth',
    title: "Australian Art Market Shows Strong Growth Potential",
    date: "December 12, 2023",
    author: "James Wilson",
    readTime: "6 min read",
    category: "Market Trends",
    image: "https://images.pexels.com/photos/1509534/pexels-photo-1509534.jpeg",
    content: [
      {
        type: "paragraph",
        text: "Australian contemporary art continues to gain international recognition, with sales increasing by 44% in 2023. This remarkable growth reflects the increasing global appreciation for African artists and their unique perspectives."
      },
      {
        type: "subheading",
        text: "Key Markets"
      },
      {
        type: "list",
        items: [
          "Vancouver: Leading the continental market with 35% share",
          "Melbourne: Strong institutional backing",
          "Sydney: Emerging artist hub",
          "Perth: Growing collector base"
        ]
      },
      // ... more content
    ]
  },
  'digital-art-authentication': {
    id: 'digital-art-authentication',
    title: "Digital Art Authentication: Blockchain Technology Revolutionizes Provenance",
    date: "December 10, 2023",
    author: "Tech Art Journal",
    readTime: "7 min read",
    category: "Technology",
    image: "https://images.pexels.com/photos/2747449/pexels-photo-2747449.jpeg",
    content: [
      // ... content
    ]
  }
  // Add more articles...
}; 