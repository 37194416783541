import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { 
  UserCircleIcon,
  Bars3Icon,
  XMarkIcon,
  Cog6ToothIcon,
  UsersIcon,
  ChartBarIcon,
  ArrowLeftOnRectangleIcon
} from '@heroicons/react/24/outline';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState(false);

  // Close mobile menu and user dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const userMenuButton = document.getElementById('user-menu-button');
      const userMenu = document.getElementById('user-dropdown');
      const mobileMenuButton = document.getElementById('mobile-menu-button');
      const mobileMenu = document.getElementById('mobile-menu');

      if (
        userMenuButton && !userMenuButton.contains(event.target) &&
        userMenu && !userMenu.contains(event.target)
      ) {
        setIsUserMenuOpen(false);
      }

      if (
        mobileMenuButton && !mobileMenuButton.contains(event.target) &&
        mobileMenu && !mobileMenu.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        setIsAdmin(userDoc.data()?.role === 'admin');
      }
    };
    checkAdminStatus();
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      setIsUserMenuOpen(false);
      setIsMenuOpen(false);
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  const isActiveLink = (path) => location.pathname === path;

  const NavLink = ({ to, children }) => (
    <Link
      to={to}
      className={`relative px-3 py-2 text-sm font-medium transition-all duration-300 
        ${isActiveLink(to) 
          ? 'text-[#891F12] after:scale-x-100' 
          : 'text-gray-700 hover:text-[#891F12] after:scale-x-0 hover:after:scale-x-100'
        } 
        after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-[#891F12] after:transition-transform after:duration-300`}
    >
      {children}
    </Link>
  );

  const getUserMenuItems = () => {
    const items = [];
    
    if (isAdmin) {
      items.push({
        name: 'Admin Dashboard',
        path: '/admin/dashboard',
        icon: Cog6ToothIcon,
      });
      items.push({
        name: 'Manage Users',
        path: '/admin/users',
        icon: UsersIcon,
      });
    } else {
      items.push({
        name: 'My Dashboard',
        path: '/dashboard',
        icon: ChartBarIcon,
      });
    }

    return items;
  };

  return (
    <nav className="fixed top-0 left-0 right-0 bg-white/90 backdrop-blur-md shadow-lg z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          {/* Logo */}
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <img 
                src="/images/Frame-100.png" 
                alt="Art Investment Logo" 
                className="h-10 w-auto transition-transform hover:scale-105"
              />
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-4 lg:space-x-6">
            <NavLink to="/about">About</NavLink>
            <NavLink to="/what-we-do">What We Do</NavLink>
            <NavLink to="/who-we-are">Who We Are</NavLink>
            <NavLink to="/events">Events</NavLink>
            <NavLink to="/news">News</NavLink>
            <NavLink to="/contact">Contact</NavLink>
            <NavLink to="/investments">Investments</NavLink>

            {/* Authentication Section */}
            {currentUser ? (
              <div className="relative ml-4">
                <button 
                  id="user-menu-button"
                  onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                  className="flex items-center space-x-2 px-4 py-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors group"
                >
                  <UserCircleIcon className="h-6 w-6 text-[#891F12] group-hover:scale-110 transition-transform" />
                  <span className="text-sm text-gray-700 truncate max-w-[150px]">
                    {currentUser.email}
                  </span>
                </button>
                
                {/* User Dropdown */}
                {isUserMenuOpen && (
                  <div 
                    id="user-dropdown"
                    className="absolute right-0 mt-2 w-56 bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden"
                  >
                    {getUserMenuItems().map((item) => (
                      <Link
                        key={item.path}
                        to={item.path}
                        onClick={() => setIsUserMenuOpen(false)}
                        className="flex items-center px-4 py-3 text-sm text-gray-700 hover:bg-[#891F12]/10 transition-colors group"
                      >
                        <item.icon className="h-5 w-5 mr-3 text-[#891F12] group-hover:scale-110 transition-transform" />
                        {item.name}
                      </Link>
                    ))}
                    <div className="border-t border-gray-100"></div>
                    <button
                      onClick={handleLogout}
                      className="w-full flex items-center px-4 py-3 text-sm text-[#891F12] hover:bg-[#891F12]/10 transition-colors group"
                    >
                      <ArrowLeftOnRectangleIcon className="h-5 w-5 mr-3 group-hover:scale-110 transition-transform" />
                      Sign out
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex items-center space-x-4">
                <Link
                  to="/login"
                  className="text-gray-600 hover:text-[#891F12] transition-colors"
                >
                  Sign in
                </Link>
                <Link
                  to="/register"
                  className="bg-[#891F12] text-white hover:bg-[#761A0F] px-6 py-2 rounded-full text-sm transition-all shadow-md hover:shadow-lg"
                >
                  Get Started
                </Link>
              </div>
            )}
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center">
            <button
              id="mobile-menu-button"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-600 hover:text-[#891F12] transition-colors"
            >
              {isMenuOpen ? (
                <XMarkIcon className="h-7 w-7" />
              ) : (
                <Bars3Icon className="h-7 w-7" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div 
        id="mobile-menu"
        className={`md:hidden transition-all duration-300 ease-in-out overflow-hidden 
          ${isMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}`}
      >
        <div className="px-2 pt-2 pb-3 space-y-1 bg-white shadow-lg">
          {[
            ['About', '/about'],
            ['What We Do', '/what-we-do'],
            ['Who We Are', '/who-we-are'],
            ['Events', '/events'],
            ['News', '/news'],
            ['Contact', '/contact'],
            ['Investments', '/investments'],
            ...(currentUser 
              ? [[isAdmin ? 'Admin Dashboard' : 'My Dashboard', isAdmin ? '/admin/dashboard' : '/dashboard']]
              : []
            )
          ].map(([title, path]) => (
            <Link
              key={path}
              to={path}
              onClick={() => setIsMenuOpen(false)}
              className={`block px-4 py-3 rounded-md text-base transition-colors
                ${isActiveLink(path) 
                  ? 'bg-[#891F12]/10 text-[#891F12]' 
                  : 'text-gray-700 hover:bg-gray-100'
                }`}
            >
              {title}
            </Link>
          ))}

          {currentUser ? (
            <button
              onClick={handleLogout}
              className="w-full text-left px-4 py-3 text-base text-[#891F12] hover:bg-[#891F12]/10 transition-colors rounded-md"
            >
              Sign out
            </button>
          ) : (
            <div className="pt-4 space-y-3 px-4">
              <Link 
                to="/login"
                onClick={() => setIsMenuOpen(false)}
                className="block w-full text-center py-3 text-gray-700 hover:text-[#891F12] transition-colors"
              >
                Sign in
              </Link>
              <Link 
                to="/register"
                onClick={() => setIsMenuOpen(false)}
                className="block w-full text-center py-3 bg-[#891F12] text-white rounded-full hover:bg-[#761A0F] transition-colors shadow-md"
              >
                Get Started
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;