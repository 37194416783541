import { useState, useEffect } from 'react';
import { db } from '../services/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { 
  ChartBarIcon, 
  CurrencyPoundIcon, 
  ArrowTrendingUpIcon,
  CalendarIcon,
  ClockIcon,
  BuildingLibraryIcon
} from '@heroicons/react/24/outline';
import NotificationCenter from '../components/notifications/NotificationCenter';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

function Dashboard() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedInvestment, setSelectedInvestment] = useState(null);
  const { currentUser } = useAuth();

  // Generate performance data from investment history
  const generatePerformanceData = (investments) => {
    const data = [];
    if (investments && investments.length > 0) {
      investments.forEach(investment => {
        investment.history.forEach(record => {
          const existingEntry = data.find(d => d.date === record.date.split('T')[0]);
          if (existingEntry) {
            existingEntry.value += record.value;
          } else {
            data.push({
              date: record.date.split('T')[0],
              value: record.value
            });
          }
        });
      });
    }
    return data.sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  const fetchUserData = async () => {
    try {
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      if (userDoc.exists()) {
        setUserData(userDoc.data());
      }
    } catch (error) {
      console.error('Error fetching investments:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchUserData();
    }
  }, [currentUser]);

  const calculateStats = () => {
    if (!userData?.investments) return { total: 0, current: 0, profit: 0, percentage: 0 };
    
    const total = userData.investments.reduce((sum, inv) => sum + inv.amount, 0);
    const current = userData.investments.reduce((sum, inv) => sum + inv.currentValue, 0);
    const profit = current - total;
    const percentage = total > 0 ? ((profit / total) * 100).toFixed(2) : 0;

    return { total, current, profit, percentage };
  };

  const stats = calculateStats();
  const performanceData = generatePerformanceData(userData?.investments);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#F8F7F4]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#891F12]"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#F8F7F4]">
      {/* Header */}
      <div className="bg-gradient-to-r from-[#891F12] to-[#761A0F] text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-3xl font-light">Investment Dashboard</h1>
              <p className="mt-1 text-sm text-[#D4C19C]">
                Welcome back, {userData?.firstName} {userData?.lastName}
              </p>
            </div>
            <NotificationCenter />
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
          <StatCard
            title="Total Invested"
            value={formatCurrency(stats.total)}
            icon={CurrencyPoundIcon}
            color="text-[#891F12]"
          />
          <StatCard
            title="Current Value"
            value={formatCurrency(stats.current)}
            icon={ChartBarIcon}
            color="text-[#891F12]"
          />
          <StatCard
            title="Total Profit/Loss"
            value={formatCurrency(stats.profit)}
            icon={ArrowTrendingUpIcon}
            trend={parseFloat(stats.percentage)}
            color="text-[#891F12]"
          />
          <StatCard
            title="Active Investments"
            value={userData?.investments?.length || 0}
            icon={BuildingLibraryIcon}
            color="text-[#891F12]"
          />
        </div>

        {/* Performance Chart */}
        <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
          <h2 className="text-xl font-semibold mb-6">Portfolio Performance</h2>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={performanceData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date" 
                  tickFormatter={(date) => new Date(date).toLocaleDateString('en-GB')}
                />
                <YAxis />
                <Tooltip 
                  formatter={(value) => formatCurrency(value)}
                  labelFormatter={(date) => new Date(date).toLocaleDateString('en-GB')}
                />
                <Area 
                  type="monotone" 
                  dataKey="value" 
                  stroke="#891F12" 
                  fill="#891F12" 
                  fillOpacity={0.1} 
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Investments Table */}
        <div className="bg-white rounded-xl shadow-sm overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-xl font-semibold">Your Investments</h2>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Package Details
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Initial Investment
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Current Value
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Profit/Loss
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Last Updated
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {userData?.investments?.map((investment) => {
                  const profit = investment.currentValue - investment.amount;
                  const percentage = ((profit / investment.amount) * 100).toFixed(2);
                  
                  return (
                    <tr 
                      key={investment.id}
                      onClick={() => setSelectedInvestment(investment)}
                      className="hover:bg-gray-50 cursor-pointer transition-colors"
                    >
                      <td className="px-6 py-4">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <BuildingLibraryIcon className="h-6 w-6 text-[#891F12]" />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {investment.packageName}
                            </div>
                            <div className="text-sm text-gray-500">
                              Started {new Date(investment.date).toLocaleDateString('en-GB')}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatCurrency(investment.amount)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatCurrency(investment.currentValue)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <span className={`${profit >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                          {formatCurrency(profit)} ({percentage}%)
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {investment.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(investment.lastUpdated).toLocaleString('en-GB')}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Investment History Modal */}
      {selectedInvestment && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-2xl w-full p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold">Investment History</h3>
              <button 
                onClick={() => setSelectedInvestment(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                ×
              </button>
            </div>
            <div className="space-y-4">
              {selectedInvestment.history?.map((record, index) => (
                <div key={index} className="border-b pb-2">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-sm text-gray-500">{record.type}</p>
                      <p className="text-sm font-medium">
                        {formatCurrency(record.value)}
                        {record.previousValue && (
                          <span className="text-gray-500 ml-2">
                            (from {formatCurrency(record.previousValue)})
                          </span>
                        )}
                      </p>
                    </div>
                    <p className="text-sm text-gray-500">
                      {new Date(record.date).toLocaleString('en-GB')}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const StatCard = ({ title, value, icon: Icon, trend, color }) => (
  <div className="bg-white rounded-xl shadow-sm p-6">
    <div className="flex justify-between">
      <div>
        <p className="text-sm text-gray-500">{title}</p>
        <h3 className="text-2xl font-semibold mt-1">{value}</h3>
        {trend !== undefined && (
          <p className={`text-sm mt-1 ${trend >= 0 ? 'text-green-600' : 'text-red-600'}`}>
            {trend > 0 ? '↑' : '↓'} {Math.abs(trend)}%
          </p>
        )}
      </div>
      <div className={`p-3 rounded-full ${color} bg-opacity-10`}>
        <Icon className={`h-6 w-6 ${color}`} />
      </div>
    </div>
  </div>
);

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

export default Dashboard;
