import { useMemo, memo, useCallback } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import LazyImage from '../components/shared/LazyImage';
import FadeIn from '../components/shared/FadeIn';
import { 
  ChartBarIcon, CurrencyPoundIcon, ShieldCheckIcon, 
  SparklesIcon, ArrowTrendingUpIcon, GlobeAltIcon 
} from '@heroicons/react/24/outline';

const PricingCard = memo(({ package: pkg, index, onGetStarted }) => (
  <FadeIn delay={index * 0.1}>
    <div className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300">
      {/* Package Header */}
      <div className="p-8 text-center border-b border-gray-100">
        <h3 className="text-2xl font-bold text-gray-900 mb-2">{pkg.title}</h3>
        <p className="text-gray-600 mb-4">{pkg.description}</p>
        <div className="text-[#891F12] mb-4">
          <span className="text-3xl font-bold">£{pkg.minInvestment}</span>
          <span className="text-gray-500 text-sm"> minimum investment</span>
        </div>
      </div>

      {/* Features List */}
      <div className="p-8">
        <ul className="space-y-4 mb-8">
          {pkg.features.map((feature, idx) => (
            <li key={idx} className="flex items-start">
              <svg className="h-6 w-6 text-[#891F12] mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
              <span className="text-gray-600">{feature}</span>
            </li>
          ))}
        </ul>

        {/* Stats */}
        <div className="space-y-3 mb-8 p-4 bg-gray-50 rounded-lg">
          <div className="flex justify-between items-center">
            <span className="text-gray-600">Expected Returns</span>
            <span className="font-medium text-[#891F12]">{pkg.stats.returns}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-gray-600">Investment Timeframe</span>
            <span className="font-medium text-[#891F12]">{pkg.stats.timeframe}</span>
          </div>
        </div>

        {/* CTA Button */}
        <button
          onClick={onGetStarted}
          className="w-full bg-[#891F12] text-white py-3 px-6 rounded-lg hover:bg-[#691812] 
            transition-colors duration-300 font-medium flex items-center justify-center"
        >
          Get Started
          <svg className="w-5 h-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
          </svg>
        </button>
      </div>
    </div>
  </FadeIn>
));

const MetricCard = memo(({ metric }) => (
  <div className="text-center">
    <h3 className="text-3xl font-bold text-[#891F12] mb-2">{metric.value}</h3>
    <p className="font-medium text-gray-900 mb-1">{metric.metric}</p>
    <p className="text-sm text-gray-600">{metric.description}</p>
  </div>
));

function Investments() {
  const navigate = useNavigate();

  const handleNavigation = useCallback((path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const investmentPackages = [
    {
      title: "Starter Portfolio",
      description: "Perfect for new collectors entering the art investment market",
      minInvestment: "25,000",
      stats: {
        returns: "15-20% p.a.",
        timeframe: "2-3 years"
      },
      features: [
        "Access to emerging artists",
        "Quarterly portfolio reviews",
        "Basic insurance coverage",
        "Digital collection management",
        "Investment advisory support"
      ]
    },
    {
      title: "Premium Portfolio",
      description: "Ideal for experienced collectors seeking established artists",
      minInvestment: "50,000",
      stats: {
        returns: "20-25% p.a.",
        timeframe: "3-5 years"
      },
      features: [
        "Access to established artists",
        "Monthly portfolio reviews",
        "Comprehensive insurance",
        "VIP gallery access",
        "Priority acquisition rights",
        "Dedicated art advisor"
      ]
    },
    {
      title: "Elite Portfolio",
      description: "Exclusive access to museum-quality masterpieces",
      minInvestment: "100,000",
      stats: {
        returns: "25-30% p.a.",
        timeframe: "5-7 years"
      },
      features: [
        "Blue-chip artworks access",
        "Weekly portfolio reviews",
        "Premium insurance coverage",
        "Private viewing events",
        "Concierge services",
        "Custom collection strategy",
        "Global exhibition access"
      ]
    }
  ];

  const performanceMetrics = [
    {
      metric: "Average Annual Return",
      value: "22.5%",
      description: "Consistent performance across our portfolio"
    },
    {
      metric: "Assets Under Management",
      value: "£50M+",
      description: "Trust from global investors"
    },
    {
      metric: "Client Satisfaction",
      value: "98%",
      description: "Based on annual client surveys"
    },
    {
      metric: "Market Coverage",
      value: "Global",
      description: "Access to international art markets"
    }
  ];

  const investmentProcess = [
    {
      title: "Portfolio Analysis",
      description: "We assess your investment goals, risk tolerance, and aesthetic preferences",
      icon: ChartBarIcon
    },
    {
      title: "Strategy Development",
      description: "Custom investment strategy aligned with your objectives",
      icon: SparklesIcon
    },
    {
      title: "Acquisition",
      description: "Strategic artwork acquisition through our global network",
      icon: GlobeAltIcon
    },
    {
      title: "Management",
      description: "Active portfolio management and performance tracking",
      icon: ArrowTrendingUpIcon
    }
  ];

  const marketInsights = [
    {
      title: "Market Growth",
      content: "The global art market reached $65.1 billion in 2023, showing resilience and growth potential.",
      icon: ArrowTrendingUpIcon
    },
    {
      title: "Digital Innovation",
      content: "NFTs and digital art platforms are creating new investment opportunities.",
      icon: SparklesIcon
    },
    {
      title: "Geographic Expansion",
      content: "Emerging art markets in Asia and Middle East are showing strong growth trajectories.",
      icon: GlobeAltIcon
    }
  ];

  const investmentBenefits = [
    {
      title: "Portfolio Diversification",
      description: "Art investments have low correlation with traditional asset classes, providing excellent diversification benefits.",
      stats: ["Low Market Correlation", "Inflation Hedge", "Stable Returns"]
    },
    {
      title: "Tax Advantages",
      description: "Various tax benefits available for art investments, including potential capital gains advantages.",
      stats: ["Tax Efficiency", "Estate Planning", "Wealth Preservation"]
    },
    {
      title: "Cultural Value",
      description: "Beyond financial returns, art investment offers cultural and social benefits.",
      stats: ["Cultural Impact", "Social Prestige", "Personal Enjoyment"]
    }
  ];

  const successStories = [
    {
      collector: "European Private Collector",
      investment: "Contemporary Portfolio",
      returns: "35% in 2 years",
      description: "Strategic investment in emerging artists led to exceptional returns through gallery partnerships."
    },
    {
      collector: "Asian Institution",
      investment: "Modern Masters",
      returns: "22% annually",
      description: "Long-term investment in established artists provided stable returns and portfolio appreciation."
    },
    {
      collector: "Middle Eastern Family Office",
      investment: "Mixed Portfolio",
      returns: "28% in 3 years",
      description: "Diversified approach across different art categories yielded strong balanced returns."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <section className="relative h-[70vh] bg-[#891F12] overflow-hidden">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.1 }}
          className="absolute inset-0 grid grid-cols-8 grid-rows-8 gap-1"
        >
          {[...Array(64)].map((_, i) => (
            <div key={i} className="bg-white/10 rounded-lg" />
          ))}
        </motion.div>
        <div className="relative h-full flex items-center justify-center text-center z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-white px-4"
          >
            <h1 className="text-5xl font-bold mb-6">Art Investment Portfolio</h1>
            <p className="text-xl text-gray-200 max-w-2xl mx-auto mb-8">
              Discover our carefully curated investment opportunities in the global art market
            </p>
            <button 
              onClick={() => handleNavigation('/contact')}
              className="bg-white text-[#891F12] px-8 py-3 rounded-lg hover:bg-gray-100 
                transition-colors font-medium inline-flex items-center space-x-2"
            >
              <span>Start Investing</span>
              <ArrowTrendingUpIcon className="h-5 w-5" />
            </button>
          </motion.div>
        </div>
      </section>

      {/* Performance Metrics */}
      <section className="relative z-10 max-w-7xl mx-auto px-4 -mt-16">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-xl shadow-xl p-8 grid grid-cols-1 md:grid-cols-4 gap-8"
        >
          {performanceMetrics.map((metric, index) => (
            <MetricCard key={index} metric={metric} />
          ))}
        </motion.div>
      </section>

      {/* Portfolio Categories */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Investment Packages</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Choose the investment package that best suits your goals and preferences
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-8">
          {investmentPackages.map((pkg, index) => (
            <PricingCard 
              key={index} 
              package={pkg} 
              index={index} 
              onGetStarted={() => handleNavigation('/contact')}
            />
          ))}
        </div>
      </section>

      {/* Investment Process */}
      <section className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Investment Process</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our streamlined process ensures a smooth journey from initial consultation to 
              portfolio management.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-4 gap-8">
            {investmentProcess.map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="relative"
              >
                <div className="bg-white p-8 rounded-xl shadow-lg text-center h-full">
                  <div className="bg-[#891F12]/10 p-4 rounded-full w-16 h-16 mx-auto mb-6">
                    <step.icon className="h-8 w-8 text-[#891F12]" />
                  </div>
                  <h3 className="text-xl font-bold text-gray-900 mb-4">{step.title}</h3>
                  <p className="text-gray-600">{step.description}</p>
                </div>
                {index < investmentProcess.length - 1 && (
                  <div className="hidden md:block absolute top-1/2 -right-4 w-8 h-0.5 bg-[#891F12]/20" />
                )}
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Market Insights Section */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Market Insights</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Stay informed about the latest trends and developments in the art investment market.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-8">
          {marketInsights.map((insight, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white p-8 rounded-xl shadow-lg"
            >
              <div className="bg-[#891F12]/10 p-4 rounded-full w-16 h-16 mb-6">
                <insight.icon className="h-8 w-8 text-[#891F12]" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-4">{insight.title}</h3>
              <p className="text-gray-600">{insight.content}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Success Stories Section */}
      <section className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Success Stories</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Real results from our portfolio management expertise.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {successStories.map((story, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white p-8 rounded-xl shadow-lg"
              >
                <div className="text-[#891F12] text-2xl font-bold mb-4">{story.returns}</div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">{story.collector}</h3>
                <p className="text-gray-600 mb-4">{story.investment}</p>
                <p className="text-gray-600">{story.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Investment Benefits Section */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Investment Benefits</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Discover the advantages of including art in your investment portfolio.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-8">
          {investmentBenefits.map((benefit, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white p-8 rounded-xl shadow-lg"
            >
              <h3 className="text-xl font-bold text-gray-900 mb-4">{benefit.title}</h3>
              <p className="text-gray-600 mb-6">{benefit.description}</p>
              <div className="space-y-2">
                {benefit.stats.map((stat, idx) => (
                  <div key={idx} className="flex items-center text-sm text-[#891F12]">
                    <span className="h-2 w-2 bg-[#891F12] rounded-full mr-2" />
                    {stat}
                  </div>
                ))}
              </div>
            </motion.div>
          ))}
        </div>
      </section>

      {/* CTA Section */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-[#891F12] text-white rounded-xl p-12 text-center"
        >
          <h2 className="text-3xl font-bold mb-4">Ready to Start Your Investment Journey?</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Join our community of successful art investors and build a portfolio that reflects your 
            aspirations.
          </p>
          <button 
            onClick={() => handleNavigation('/contact')}
            className="bg-white text-[#891F12] px-8 py-3 rounded-lg hover:bg-gray-100 
              transition-colors font-medium inline-flex items-center space-x-2"
          >
            <span>Schedule a Consultation</span>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-5 w-5" 
              viewBox="0 0 20 20" 
              fill="currentColor"
            >
              <path 
                fillRule="evenodd" 
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" 
                clipRule="evenodd" 
              />
            </svg>
          </button>
        </motion.div>
      </section>
    </div>
  );
}

export default Investments; 