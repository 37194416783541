import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  ArrowLeftIcon, 
  EnvelopeIcon, 
  LinkedInIcon,
  PhoneIcon,
  MapPinIcon 
} from '@heroicons/react/24/outline';

// Team members data - you might want to move this to a separate data file
export const teamMembers = {
  sarah: {
    name: 'Sarah Mitchell',
    role: 'Chief Executive Officer',
    image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330',
    description: '15+ years in art investment',
    longDescription: `Sarah Mitchell brings over 15 years of experience in art investment and gallery management. 
    She has successfully guided numerous collectors in building valuable art portfolios and has been featured in 
    leading art publications.`,
    education: ['MBA, Harvard Business School', 'BA Art History, Yale University'],
    achievements: [
      'Named "Top 40 Under 40" in Art Business',
      'Led acquisitions worth over £100M',
      'Keynote speaker at Art Basel 2023'
    ],
    contact: {
      email: 'sarah.mitchell@eagleton.com',
      phone: '+44 20 1234 5678',
      location: 'London, UK'
    },
    linkedin: 'https://linkedin.com/in/sarah-mitchell'
  },
  james: {
    name: 'James Wilson',
    role: 'Creative Director',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e',
    description: 'Former gallery curator',
    longDescription: `James Wilson has curated exhibitions at some of the world's most prestigious galleries. 
    His keen eye for emerging talent has helped discover several notable artists.`,
    education: ['MA Fine Arts, Royal College of Art', 'BA Curating, Central Saint Martins'],
    achievements: [
      'Curator of the Year 2022',
      'Published author on contemporary art',
      'Judge at Turner Prize'
    ],
    contact: {
      email: 'james.wilson@eagleton.com',
      phone: '+44 20 1234 5679',
      location: 'London, UK'
    },
    linkedin: 'https://linkedin.com/in/james-wilson'
  },
  // Add other team members...
};

function TeamProfile() {
  const { id } = useParams();
  const member = teamMembers[id];

  if (!member) {
    return <div>Profile not found</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4">
        <Link 
          to="/" 
          className="inline-flex items-center text-blue-600 hover:text-blue-800 mb-8"
        >
          <ArrowLeftIcon className="h-5 w-5 mr-2" />
          Back to Home
        </Link>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-lg shadow-lg overflow-hidden"
        >
          <div className="md:flex">
            <div className="md:flex-shrink-0">
              <img
                className="h-48 w-full object-cover md:h-full md:w-48"
                src={member.image}
                alt={member.name}
              />
            </div>
            <div className="p-8">
              <h1 className="text-3xl font-bold text-gray-900 mb-2">
                {member.name}
              </h1>
              <p className="text-blue-600 font-medium mb-4">{member.role}</p>
              <p className="text-gray-600 mb-6">{member.longDescription}</p>
              
              <div className="grid md:grid-cols-2 gap-8">
                <div>
                  <h2 className="text-xl font-bold mb-4">Education</h2>
                  <ul className="space-y-2">
                    {member.education.map((edu, index) => (
                      <li key={index} className="text-gray-600">{edu}</li>
                    ))}
                  </ul>
                </div>
                
                <div>
                  <h2 className="text-xl font-bold mb-4">Achievements</h2>
                  <ul className="space-y-2">
                    {member.achievements.map((achievement, index) => (
                      <li key={index} className="text-gray-600">{achievement}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="mt-8 pt-8 border-t border-gray-200">
                <h2 className="text-xl font-bold mb-4">Contact Information</h2>
                <div className="space-y-4">
                  <div className="flex items-center">
                    <EnvelopeIcon className="h-5 w-5 text-gray-400 mr-2" />
                    <a href={`mailto:${member.contact.email}`} className="text-blue-600 hover:text-blue-800">
                      {member.contact.email}
                    </a>
                  </div>
                  <div className="flex items-center">
                    <PhoneIcon className="h-5 w-5 text-gray-400 mr-2" />
                    <a href={`tel:${member.contact.phone}`} className="text-blue-600 hover:text-blue-800">
                      {member.contact.phone}
                    </a>
                  </div>
                  <div className="flex items-center">
                    <MapPinIcon className="h-5 w-5 text-gray-400 mr-2" />
                    <span className="text-gray-600">{member.contact.location}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default TeamProfile; 