function Footer() {
  return (
    <footer className="bg-[#2A0F0A] text-white">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4 text-[#D4C19C]">Eagleton Art & Design</h3>
            <p className="text-gray-300">Where creativity meets investment</p>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4 text-[#D4C19C]">Quick Links</h4>
            <ul className="space-y-2">
              <li>
                <a href="/about" className="text-gray-300 hover:text-[#D4C19C] transition-colors">
                  About
                </a>
              </li>
              <li>
                <a href="/what-we-do" className="text-gray-300 hover:text-[#D4C19C] transition-colors">
                  What We Do
                </a>
              </li>
              <li>
                <a href="/who-we-are" className="text-gray-300 hover:text-[#D4C19C] transition-colors">
                  Who We Are
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4 text-[#D4C19C]">Contact</h4>
            <ul className="space-y-2">
              <li className="text-gray-300">2 Leicester Place</li>
              <li className="text-gray-300">London, UK</li>
              <li>
                <a 
                  href="mailto:info@eagletonartdesign.co.uk" 
                  className="text-gray-300 hover:text-[#D4C19C] transition-colors"
                >
                  info@eagletonartdesign.co.uk
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4 text-[#D4C19C]">Follow Us</h4>
            <div className="flex space-x-4">
              <a 
                href="#" 
                className="text-gray-300 hover:text-[#D4C19C] transition-colors"
                aria-label="Twitter"
              >
                Twitter
              </a>
              <a 
                href="#" 
                className="text-gray-300 hover:text-[#D4C19C] transition-colors"
                aria-label="Instagram"
              >
                Instagram
              </a>
              <a 
                href="#" 
                className="text-gray-300 hover:text-[#D4C19C] transition-colors"
                aria-label="LinkedIn"
              >
                LinkedIn
              </a>
            </div>
          </div>
        </div>
        <div className="border-t border-[#3D1510] mt-8 pt-8 text-center text-gray-400">
          <p>&copy; 2024 Eagleton Art & Design. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
