import { memo } from 'react';

const LazyImage = memo(({ src, alt, className }) => (
  <img 
    loading="lazy"
    src={src}
    alt={alt}
    className={className}
    decoding="async"
  />
));

export default LazyImage; 