import { motion } from 'framer-motion';
import { 
  UserGroupIcon, GlobeAltIcon, SparklesIcon, 
  AcademicCapIcon, CursorArrowRaysIcon, HeartIcon,
  BuildingOfficeIcon, UsersIcon, TrophyIcon
} from '@heroicons/react/24/outline';

function About() {
  const stats = [
    { number: "13+", label: "Years Experience" },
    { number: "£50M+", label: "Art Traded" },
    { number: "1000+", label: "Happy Clients" },
    { number: "25+", label: "Global Partners" }
  ];

  const values = [
    {
      icon: UserGroupIcon,
      title: "Client-Focused",
      description: "We put our clients' interests first, providing personalized investment strategies tailored to individual goals and preferences."
    },
    {
      icon: GlobeAltIcon,
      title: "Global Reach",
      description: "Connected with leading galleries, artists, and collectors worldwide, offering unique international investment opportunities."
    },
    {
      icon: SparklesIcon,
      title: "Innovation",
      description: "Pioneering new approaches to art investment through advanced analytics and market intelligence."
    },
    {
      icon: AcademicCapIcon,
      title: "Expert Knowledge",
      description: "Our team comprises art historians, market analysts, and investment specialists providing comprehensive insights."
    },
    {
      icon: CursorArrowRaysIcon,
      title: "Digital First",
      description: "Leveraging technology to make art investment accessible and transparent for all our clients."
    },
    {
      icon: HeartIcon,
      title: "Passion for Art",
      description: "Combining investment expertise with a genuine love for art and supporting artistic communities."
    }
  ];

  const achievements = [
    {
      icon: BuildingOfficeIcon,
      title: "Global Presence",
      stats: "5 International Offices",
      description: "Strategic locations in major art markets worldwide"
    },
    {
      icon: UsersIcon,
      title: "Growing Community",
      stats: "10,000+ Members",
      description: "Active community of collectors and investors"
    },
    {
      icon: TrophyIcon,
      title: "Industry Recognition",
      stats: "15+ Awards",
      description: "Excellence in art investment services"
    }
  ];

  const milestones = [
    { 
      year: "2010", 
      event: "Founded in London",
      description: "Started with a vision to democratize art investment"
    },
    { 
      year: "2013", 
      event: "Launched First Art Fund",
      description: "Pioneered accessible art investment opportunities"
    },
    { 
      year: "2015", 
      event: "European Expansion",
      description: "Opened offices in Paris and Berlin"
    },
    { 
      year: "2018", 
      event: "Digital Innovation",
      description: "Introduced AI-powered art market analytics"
    },
    { 
      year: "2020", 
      event: "£50M Milestone",
      description: "Reached £50M in managed art assets"
    },
    { 
      year: "2023", 
      event: "Global Growth",
      description: "Expanded to New York and Tokyo markets"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <section className="relative h-[60vh] bg-cover bg-center" 
        style={{ backgroundImage: "url('https://images.unsplash.com/photo-1577083552431-6e5fd01aa342')" }}>
        <div className="absolute inset-0 bg-black/50" />
        <div className="relative h-full flex items-center justify-center text-center z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-white px-4"
          >
            <h1 className="text-5xl font-bold mb-4">About Eagleton Art & Design</h1>
            <p className="text-xl text-gray-200 max-w-2xl mx-auto">
              Pioneering the future of art investment since 2010, combining artistic 
              excellence with innovative investment strategies.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Introduction Section */}
      <section className="relative z-20 max-w-7xl mx-auto px-4 -mt-16">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="bg-white rounded-xl shadow-xl p-8 mb-16"
        >
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h2 className="text-3xl font-bold text-[#2A0F0A] mb-4">Our Story</h2>
              <p className="text-gray-600 mb-6">
                Founded with a vision to democratize art investment, Eagleton Art & Design 
                has grown from a small London gallery to a global art investment leader. 
                We combine traditional art expertise with modern investment strategies.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              {stats.map((stat, index) => (
                <div key={index} className="text-center p-4 bg-gray-50 rounded-lg">
                  <h3 className="text-3xl font-bold text-[#891F12] mb-2">{stat.number}</h3>
                  <p className="text-gray-600">{stat.label}</p>
                </div>
              ))}
            </div>
          </div>
        </motion.div>
      </section>

      {/* Mission Section */}
      <section className="max-w-7xl mx-auto px-4 mb-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="grid md:grid-cols-2 gap-12 items-center"
        >
          <div className="order-2 md:order-1">
            <h2 className="text-3xl font-bold text-[#2A0F0A] mb-4">Our Mission</h2>
            <p className="text-gray-600 mb-6">
              We strive to make art investment accessible while supporting artists and 
              preserving cultural heritage. Our approach combines financial expertise 
              with a deep appreciation for art.
            </p>
            <ul className="space-y-4">
              {[
                "Democratizing art investment",
                "Supporting emerging artists",
                "Preserving cultural heritage",
                "Innovating investment strategies"
              ].map((item, index) => (
                <li key={index} className="flex items-center text-gray-600">
                  <span className="h-2 w-2 bg-[#891F12] rounded-full mr-3" />
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="order-1 md:order-2">
            <img 
              src="https://images.unsplash.com/photo-1594732832278-abd644401426"
              alt="Art Gallery"
              className="rounded-lg shadow-lg w-full h-[400px] object-cover"
            />
          </div>
        </motion.div>
      </section>

      {/* Values Section */}
      <section className="max-w-7xl mx-auto px-4 py-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-[#2A0F0A] mb-4">Our Values</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            The principles that guide our mission to transform art investment
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-8">
          {values.map((value, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow"
            >
              <value.icon className="h-12 w-12 text-[#891F12] mb-6" />
              <h3 className="text-xl font-bold text-[#2A0F0A] mb-4">{value.title}</h3>
              <p className="text-gray-600">{value.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Continue with Achievements, Timeline, and CTA sections... */}
    </div>
  );
}

export default About;
