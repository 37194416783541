import { useState } from 'react';
import {
  ChartBarIcon,
  FolderIcon,
  DocumentTextIcon,
  CurrencyPoundIcon,
  UserPlusIcon,
  Bars3Icon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import PortfolioManagement from './sections/PortfolioManagement';
import InvestmentControl from './sections/InvestmentControl';
import ActivityMonitor from './sections/ActivityMonitor';
import MarketAnalysis from './sections/MarketAnalysis';
import CreateUserForm from '../../components/admin/CreateUserForm';

const AdminDashboard = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const navigationItems = [
    {
      name: 'Investment Control',
      icon: ChartBarIcon,
      component: <InvestmentControl />
    },
    {
      name: 'Portfolio Management',
      icon: FolderIcon,
      component: <PortfolioManagement />
    },
    {
      name: 'Activity Monitor',
      icon: DocumentTextIcon,
      component: <ActivityMonitor />
    },
    {
      name: 'Market Analysis',
      icon: CurrencyPoundIcon,
      component: <MarketAnalysis />
    }
  ];

  const handleCreateSuccess = () => {
    setShowCreateUser(false);
  };

  const handleCreateError = (error) => {
    console.error('Error creating user:', error);
  };

  const handleTabChange = (index) => {
    setSelectedTab(index);
    setIsMobileMenuOpen(false);
  };

  return (
    <div className="min-h-screen bg-[#F8F7F4]">
      {/* Header */}
      <div className="bg-gradient-to-r from-[#891F12] to-[#761A0F] text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
            <div className="flex items-center justify-between w-full sm:w-auto">
              <div>
                <h1 className="text-2xl sm:text-3xl font-light">Administration Portal</h1>
                <p className="mt-1 text-sm text-[#D4C19C]">Art Investment Management</p>
              </div>
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="sm:hidden"
              >
                {isMobileMenuOpen ? (
                  <XMarkIcon className="h-6 w-6" />
                ) : (
                  <Bars3Icon className="h-6 w-6" />
                )}
              </button>
            </div>
            <button
              onClick={() => setShowCreateUser(true)}
              className="w-full sm:w-auto flex items-center justify-center px-4 py-2 bg-white text-[#891F12] rounded-lg hover:bg-[#D4C19C] transition-colors duration-200 shadow-sm"
            >
              <UserPlusIcon className="h-5 w-5 mr-2" />
              Add New Investor
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="grid grid-cols-1 sm:grid-cols-12 gap-6">
          {/* Sidebar - Mobile Dropdown */}
          <div className={`
            sm:hidden fixed inset-0 z-50 bg-black bg-opacity-50 transition-opacity duration-300
            ${isMobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}
          `}>
            <div className={`
              bg-white w-64 h-full transition-transform duration-300 transform
              ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}
            `}>
              <nav className="p-4 space-y-2">
                {navigationItems.map((item, index) => (
                  <button
                    key={item.name}
                    onClick={() => handleTabChange(index)}
                    className={`w-full flex items-center px-4 py-3 rounded-lg transition-colors duration-200
                      ${selectedTab === index 
                        ? 'bg-[#891F12] text-white' 
                        : 'text-gray-600 hover:bg-[#891F12] hover:text-white'}`}
                  >
                    <item.icon className="h-5 w-5 mr-3" />
                    <span className="text-sm">{item.name}</span>
                  </button>
                ))}
              </nav>
            </div>
          </div>

          {/* Sidebar */}
          <div className="col-span-2">
            <nav className="space-y-2">
              {navigationItems.map((item, index) => (
                <button
                  key={item.name}
                  onClick={() => setSelectedTab(index)}
                  className={`w-full flex items-center px-4 py-3 rounded-lg transition-colors duration-200
                    ${selectedTab === index 
                      ? 'bg-[#891F12] text-white' 
                      : 'text-gray-600 hover:bg-[#891F12] hover:text-white'}`}
                >
                  <item.icon className="h-5 w-5 mr-3" />
                  <span className="text-sm">{item.name}</span>
                </button>
              ))}
            </nav>
          </div>

          {/* Content Area */}
          <div className="col-span-10">
            <div className="bg-white rounded-xl shadow-sm">
              {navigationItems[selectedTab].component}
            </div>
          </div>
        </div>
      </div>

      {/* Create User Modal */}
      {showCreateUser && (
        <CreateUserForm
          onSuccess={handleCreateSuccess}
          onError={handleCreateError}
          onClose={() => setShowCreateUser(false)}
        />
      )}
    </div>
  );
};

export default AdminDashboard; 