import { useState } from 'react';
import { 
  ClockIcon, 
  UserCircleIcon,
  CurrencyPoundIcon,
  ArrowPathIcon,
  ArrowDownTrayIcon
} from '@heroicons/react/24/outline';

const SAMPLE_ACTIVITIES = [
  {
    id: 'act1',
    type: 'investment_update',
    user: {
      name: 'John Smith',
      email: 'john.smith@example.com'
    },
    details: {
      packageName: 'Premium Art Collection',
      oldValue: 25000,
      newValue: 28750
    },
    timestamp: '2024-03-15T14:30:00'
  },
  {
    id: 'act2',
    type: 'new_investment',
    user: {
      name: 'Emma Wilson',
      email: 'emma.wilson@example.com'
    },
    details: {
      packageName: 'Modern Art Fund',
      value: 50000
    },
    timestamp: '2024-03-14T11:15:00'
  },
  {
    id: 'act3',
    type: 'user_created',
    user: {
      name: 'Sarah Parker',
      email: 'sarah.parker@example.com'
    },
    details: {
      packageName: 'Starter Art Collection',
      initialInvestment: 5000
    },
    timestamp: '2024-03-13T09:45:00'
  }
];

const ActivityMonitor = () => {
  const [activities] = useState(SAMPLE_ACTIVITIES);
  const [filter, setFilter] = useState('all');

  const getActivityIcon = (type) => {
    switch(type) {
      case 'investment_update':
        return <ArrowPathIcon className="h-5 w-5 text-[#891F12]" />;
      case 'new_investment':
        return <CurrencyPoundIcon className="h-5 w-5 text-green-600" />;
      case 'user_created':
        return <UserCircleIcon className="h-5 w-5 text-blue-600" />;
      default:
        return <ClockIcon className="h-5 w-5 text-gray-400" />;
    }
  };

  const getActivityDescription = (activity) => {
    switch(activity.type) {
      case 'investment_update':
        return `Updated investment value from ${formatCurrency(activity.details.oldValue)} to ${formatCurrency(activity.details.newValue)}`;
      case 'new_investment':
        return `New investment of ${formatCurrency(activity.details.value)}`;
      case 'user_created':
        return `New investor account created with initial investment of ${formatCurrency(activity.details.initialInvestment)}`;
      default:
        return 'Activity logged';
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP'
    }).format(amount);
  };

  return (
    <div className="p-4 sm:p-6 space-y-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
        <h2 className="text-xl font-semibold">Activity Monitor</h2>
        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 w-full sm:w-auto">
          <select 
            className="rounded-lg border-gray-300 text-sm w-full sm:w-auto"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="all">All Activities</option>
            <option value="investment_update">Investment Updates</option>
            <option value="new_investment">New Investments</option>
            <option value="user_created">New Users</option>
          </select>
          <button className="flex items-center justify-center px-4 py-2 bg-[#891F12] text-white rounded-lg text-sm hover:bg-[#761A0F] transition-colors w-full sm:w-auto">
            <ArrowDownTrayIcon className="h-5 w-5 mr-2" />
            Export Log
          </button>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm">
        <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">
          {activities
            .filter(activity => filter === 'all' || activity.type === filter)
            .map(activity => (
              <div 
                key={activity.id}
                className="flex items-start space-x-4 p-4 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <div className="p-2 rounded-full bg-gray-100">
                  {getActivityIcon(activity.type)}
                </div>
                
                <div className="flex-1">
                  <div className="flex justify-between">
                    <div>
                      <p className="font-medium text-gray-900">
                        {activity.user.name}
                      </p>
                      <p className="text-sm text-gray-500">
                        {getActivityDescription(activity)}
                      </p>
                    </div>
                    <p className="text-sm text-gray-400">
                      {new Date(activity.timestamp).toLocaleString('en-GB')}
                    </p>
                  </div>
                  <div className="mt-2 text-sm text-gray-500">
                    Package: {activity.details.packageName}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ActivityMonitor; 