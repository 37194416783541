import { useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar
} from 'recharts';
import { 
  ArrowTrendingUpIcon,
  CurrencyPoundIcon,
  ChartBarIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';

const SAMPLE_DATA = {
  performanceData: [
    { month: 'Jan', value: 2200000 },
    { month: 'Feb', value: 2350000 },
    { month: 'Mar', value: 2750000 },
    { month: 'Apr', value: 2900000 },
    { month: 'May', value: 3100000 }
  ],
  packagePerformance: [
    { name: 'Starter', current: 850000, previous: 800000 },
    { name: 'Professional', current: 1200000, previous: 1000000 },
    { name: 'Premium', current: 1050000, previous: 900000 }
  ],
  marketStats: {
    totalPortfolioValue: 3100000,
    monthlyGrowth: 15.2,
    activeInvestors: 45,
    averageInvestment: 68889,
    topPerformingPackages: [
      {
        name: 'Premium Art Collection',
        growth: 18.5,
        totalValue: 1200000
      },
      {
        name: 'Modern Art Fund',
        growth: 15.2,
        totalValue: 950000
      },
      {
        name: 'Contemporary Portfolio',
        growth: 12.8,
        totalValue: 600000
      }
    ]
  }
};

const MarketAnalysis = () => {
  const [data] = useState(SAMPLE_DATA);

  return (
    <div className="p-4 sm:p-6 space-y-6">
      {/* Market Overview Stats */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
        <StatCard
          title="Total Portfolio Value"
          value={formatCurrency(data.marketStats.totalPortfolioValue)}
          icon={CurrencyPoundIcon}
          trend={data.marketStats.monthlyGrowth}
        />
        <StatCard
          title="Monthly Growth"
          value={`${data.marketStats.monthlyGrowth}%`}
          icon={ArrowTrendingUpIcon}
          trend={data.marketStats.monthlyGrowth}
        />
        <StatCard
          title="Active Investors"
          value={data.marketStats.activeInvestors}
          icon={UserGroupIcon}
        />
        <StatCard
          title="Average Investment"
          value={formatCurrency(data.marketStats.averageInvestment)}
          icon={ChartBarIcon}
        />
      </div>

      {/* Performance Chart */}
      <div className="bg-white p-4 sm:p-6 rounded-xl shadow-sm">
        <h3 className="text-lg font-medium mb-4 sm:mb-6">Portfolio Performance</h3>
        <div className="h-64 sm:h-80">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={data.performanceData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip 
                formatter={(value) => formatCurrency(value)}
                labelStyle={{ color: '#891F12' }}
              />
              <Area 
                type="monotone" 
                dataKey="value" 
                stroke="#891F12" 
                fill="#891F12" 
                fillOpacity={0.1} 
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Package Performance Comparison */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
        <div className="bg-white p-4 sm:p-6 rounded-xl shadow-sm">
          <h3 className="text-lg font-medium mb-4 sm:mb-6">Package Performance</h3>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={data.packagePerformance}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip formatter={(value) => formatCurrency(value)} />
                <Bar dataKey="current" fill="#891F12" name="Current Value" />
                <Bar dataKey="previous" fill="#D4C19C" name="Previous Value" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Top Performing Packages */}
        <div className="bg-white p-4 sm:p-6 rounded-xl shadow-sm">
          <h3 className="text-lg font-medium mb-4 sm:mb-6">Top Performing Packages</h3>
          <div className="space-y-4">
            {data.marketStats.topPerformingPackages.map((pkg, index) => (
              <div 
                key={pkg.name}
                className="flex items-center justify-between p-4 rounded-lg bg-gray-50"
              >
                <div>
                  <h4 className="font-medium">{pkg.name}</h4>
                  <p className="text-sm text-gray-500">
                    Total Value: {formatCurrency(pkg.totalValue)}
                  </p>
                </div>
                <div className="text-right">
                  <p className="text-lg font-semibold text-green-600">
                    +{pkg.growth}%
                  </p>
                  <p className="text-sm text-gray-500">Growth</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const StatCard = ({ title, value, icon: Icon, trend }) => (
  <div className="bg-white p-6 rounded-xl shadow-sm">
    <div className="flex justify-between">
      <div>
        <p className="text-sm text-gray-500">{title}</p>
        <h3 className="text-2xl font-semibold mt-1">{value}</h3>
        {trend && (
          <p className={`text-sm mt-1 ${trend >= 0 ? 'text-green-600' : 'text-red-600'}`}>
            {trend > 0 ? '↑' : '↓'} {Math.abs(trend)}%
          </p>
        )}
      </div>
      <div className="p-3 rounded-full bg-[#891F12] bg-opacity-10">
        <Icon className="h-6 w-6 text-[#891F12]" />
      </div>
    </div>
  </div>
);

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  }).format(amount);
};

export default MarketAnalysis; 