import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  CalendarIcon, UserIcon, ClockIcon, 
  ShareIcon, BookmarkIcon, ArrowLeftIcon 
} from '@heroicons/react/24/outline';

function ArticlePage() {
  const { articleId } = useParams();
  const navigate = useNavigate();

  // This would typically come from an API - using mock data for demonstration
  const articles = {
    'global-art-market-2023': {
      title: "Global Art Market Reaches Record $95.2 Billion in 2023",
      date: "December 15, 2023",
      author: "Sarah Mitchell",
      readTime: "8 min read",
      category: "Market Analysis",
      image: "https://images.pexels.com/photos/1647121/pexels-photo-1647121.jpeg",
      content: [
        {
          type: "paragraph",
          text: "The global art market has achieved unprecedented growth, reaching $95.2 billion in total sales for 2023, marking a 17% increase from the previous year. This remarkable growth has been driven by several key factors, including the expansion of digital art sales and increased participation from emerging markets."
        },
        {
          type: "subheading",
          text: "Key Growth Drivers"
        },
        {
          type: "list",
          items: [
            "Digital art sector expansion reaching $3.5 billion",
            "Emerging markets contributing 35% of total sales",
            "Record-breaking auctions in contemporary art",
            "Increased institutional investment"
          ]
        },
        {
          type: "paragraph",
          text: "The digital art sector has seen particularly strong growth, with NFT sales and digital platforms contributing significantly to the overall market expansion. This trend indicates a fundamental shift in how art is bought, sold, and collected."
        },
        {
          type: "quote",
          text: "The art market's resilience and adaptation to digital transformation has been remarkable. We're seeing a new generation of collectors who are comfortable with both traditional and digital art forms.",
          author: "James Chen, Head of Art Market Research"
        },
        {
          type: "subheading",
          text: "Regional Performance"
        },
        {
          type: "paragraph",
          text: "Asia continues to be a major driver of growth, with China and Hong Kong leading the region. The European market has shown strong recovery, while the US maintains its position as the largest single market."
        },
        {
          type: "statistics",
          data: [
            { label: "North America", value: "42%" },
            { label: "Europe", value: "31%" },
            { label: "Asia", value: "23%" },
            { label: "Rest of World", value: "4%" }
          ]
        },
        {
          type: "subheading",
          text: "Future Outlook"
        },
        {
          type: "paragraph",
          text: "Analysts predict continued growth in 2024, with particular emphasis on sustainable art practices and increased transparency in transactions through blockchain technology."
        }
      ],
      relatedArticles: [
        {
          title: "Digital Art Authentication: Blockchain Technology Revolutionizes Provenance",
          date: "December 10, 2023",
          image: "https://images.pexels.com/photos/2747449/pexels-photo-2747449.jpeg"
        },
        {
          title: "Art Investment Funds See Record Inflows in Q4 2023",
          date: "December 5, 2023",
          image: "https://images.pexels.com/photos/2228569/pexels-photo-2228569.jpeg"
        }
      ]
    },
    // Add more articles...
  };

  const article = articles[articleId];

  if (!article) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Article not found</h2>
          <button
            onClick={() => navigate('/news')}
            className="text-[#891F12] font-medium hover:underline"
          >
            Return to News
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <section className="relative h-[60vh] bg-[#891F12]">
        <div className="absolute inset-0">
          <img 
            src={article.image}
            alt={article.title}
            className="w-full h-full object-cover opacity-30"
          />
        </div>
        <div className="relative h-full flex items-center justify-center text-center z-10">
          <div className="text-white px-4 max-w-4xl">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <span className="inline-block bg-white/20 text-white px-3 py-1 rounded-full text-sm font-medium mb-4">
                {article.category}
              </span>
              <h1 className="text-4xl md:text-5xl font-bold mb-6">{article.title}</h1>
              <div className="flex items-center justify-center space-x-4 text-gray-200">
                <div className="flex items-center">
                  <UserIcon className="h-5 w-5 mr-2" />
                  <span>{article.author}</span>
                </div>
                <div className="flex items-center">
                  <CalendarIcon className="h-5 w-5 mr-2" />
                  <span>{article.date}</span>
                </div>
                <div className="flex items-center">
                  <ClockIcon className="h-5 w-5 mr-2" />
                  <span>{article.readTime}</span>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Article Content */}
      <section className="max-w-4xl mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-xl shadow-lg p-8 md:p-12"
        >
          <div className="flex justify-between items-center mb-8">
            <button
              onClick={() => navigate('/news')}
              className="text-gray-600 hover:text-[#891F12] flex items-center"
            >
              <ArrowLeftIcon className="h-5 w-5 mr-2" />
              Back to News
            </button>
            <div className="flex space-x-4">
              <button className="text-gray-600 hover:text-[#891F12]">
                <ShareIcon className="h-5 w-5" />
              </button>
              <button className="text-gray-600 hover:text-[#891F12]">
                <BookmarkIcon className="h-5 w-5" />
              </button>
            </div>
          </div>

          <div className="prose prose-lg max-w-none">
            {article.content.map((section, index) => {
              switch (section.type) {
                case 'paragraph':
                  return <p key={index} className="text-gray-600 mb-6">{section.text}</p>;
                case 'subheading':
                  return <h2 key={index} className="text-2xl font-bold text-gray-900 mb-4">{section.text}</h2>;
                case 'list':
                  return (
                    <ul key={index} className="list-disc list-inside mb-6">
                      {section.items.map((item, idx) => (
                        <li key={idx} className="text-gray-600 mb-2">{item}</li>
                      ))}
                    </ul>
                  );
                case 'quote':
                  return (
                    <blockquote key={index} className="border-l-4 border-[#891F12] pl-4 my-6">
                      <p className="text-gray-600 italic mb-2">{section.text}</p>
                      <cite className="text-sm text-gray-500">— {section.author}</cite>
                    </blockquote>
                  );
                case 'statistics':
                  return (
                    <div key={index} className="grid grid-cols-2 md:grid-cols-4 gap-4 my-6">
                      {section.data.map((stat, idx) => (
                        <div key={idx} className="text-center">
                          <div className="text-2xl font-bold text-[#891F12]">{stat.value}</div>
                          <div className="text-sm text-gray-500">{stat.label}</div>
                        </div>
                      ))}
                    </div>
                  );
                default:
                  return null;
              }
            })}
          </div>
        </motion.div>
      </section>

      {/* Related Articles */}
      <section className="max-w-4xl mx-auto px-4 pb-20">
        <h2 className="text-2xl font-bold text-gray-900 mb-8">Related Articles</h2>
        <div className="grid md:grid-cols-2 gap-8">
          {article.relatedArticles.map((related, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white rounded-xl shadow-lg overflow-hidden group cursor-pointer"
              onClick={() => navigate(`/news/${related.id}`)}
            >
              <div className="h-48 relative overflow-hidden">
                <img 
                  src={related.image}
                  alt={related.title}
                  className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-500"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-900 mb-2">{related.title}</h3>
                <div className="flex items-center text-gray-500">
                  <CalendarIcon className="h-5 w-5 mr-2" />
                  <span>{related.date}</span>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default ArticlePage; 