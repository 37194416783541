import { where } from 'firebase/firestore';
import { useRealTimeData } from '../../../hooks/useRealTimeData';
import { useState } from 'react';
import {
  UserGroupIcon,
  CurrencyPoundIcon,
  ChartBarIcon,
  ArrowTrendingUpIcon,
  PlusIcon
} from '@heroicons/react/24/outline';
import CreateUserForm from '../../../components/admin/CreateUserForm';

const SAMPLE_USERS = [
  {
    id: 'sample1',
    firstName: 'John',
    lastName: 'Smith',
    email: 'john.smith@example.com',
    joinDate: '2024-01-15',
    investments: [
      {
        id: 'inv1',
        packageName: 'Premium Art Collection',
        amount: 25000,
        currentValue: 28750,
        date: '2024-02-15',
        status: 'active',
        lastUpdated: '2024-03-15',
        history: [
          {
            date: '2024-02-15',
            value: 25000,
            type: 'initial investment'
          },
          {
            date: '2024-03-15',
            value: 28750,
            type: 'value update'
          }
        ]
      }
    ]
  },
  {
    id: 'sample2',
    firstName: 'Emma',
    lastName: 'Wilson',
    email: 'emma.wilson@example.com',
    joinDate: '2024-02-01',
    investments: [
      {
        id: 'inv2',
        packageName: 'Modern Art Fund',
        amount: 50000,
        currentValue: 57500,
        date: '2024-01-20',
        status: 'active',
        lastUpdated: '2024-03-15',
        history: [
          {
            date: '2024-01-20',
            value: 50000,
            type: 'initial investment'
          },
          {
            date: '2024-03-15',
            value: 57500,
            type: 'value update'
          }
        ]
      }
    ]
  }
];

const PortfolioManagement = () => {
  const { data: firebaseUsers, loading } = useRealTimeData('users', [
    where('role', '==', 'user')
  ]);
  
  // Use Firebase data if available, otherwise use sample data
  const users = firebaseUsers?.length > 0 ? firebaseUsers : SAMPLE_USERS;

  const calculateStats = () => {
    const totalInvestments = users.reduce((acc, user) => 
      acc + user.investments.length, 0);

    const totalValue = users.reduce((acc, user) => 
      acc + user.investments.reduce((sum, inv) => sum + inv.currentValue, 0), 0);

    const totalInitial = users.reduce((acc, user) => 
      acc + user.investments.reduce((sum, inv) => sum + inv.amount, 0), 0);

    const averageReturn = ((totalValue - totalInitial) / totalInitial) * 100;

    return {
      totalInvestors: users.length,
      totalInvestments,
      totalValue,
      averageReturn
    };
  };

  const stats = calculateStats();

  const [showCreateUser, setShowCreateUser] = useState(false);

  const handleCreateSuccess = () => {
    setShowCreateUser(false);
    // The real-time hook will automatically update the data
  };

  const handleCreateError = (error) => {
    console.error('Error creating user:', error);
    // You might want to add a toast notification here
  };

  return (
    <div className="p-4 sm:p-6 space-y-6">
      {/* Stats Overview */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
        <StatCard
          title="Total Investors"
          value={stats.totalInvestors}
          icon={UserGroupIcon}
          color="text-[#891F12]"
        />
        <StatCard
          title="Active Investments"
          value={stats.totalInvestments}
          icon={ChartBarIcon}
          color="text-[#891F12]"
        />
        <StatCard
          title="Total Portfolio Value"
          value={formatCurrency(stats.totalValue)}
          icon={CurrencyPoundIcon}
          color="text-[#891F12]"
        />
        <StatCard
          title="Average Return"
          value={`${stats.averageReturn.toFixed(1)}%`}
          icon={ArrowTrendingUpIcon}
          trend={stats.averageReturn}
          color="text-[#891F12]"
        />
      </div>

      {/* User List */}
      <div className="bg-white rounded-xl shadow-sm">
        <div className="p-4 sm:p-6 border-b border-gray-100 flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
          <h2 className="text-lg font-medium">Investor Portfolios</h2>
          <button 
            onClick={() => setShowCreateUser(true)}
            className="w-full sm:w-auto flex items-center justify-center px-4 py-2 bg-[#891F12] text-white rounded-lg hover:bg-[#761A0F] transition-colors"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            Add New Investor
          </button>
        </div>
        
        <div className="p-4 sm:p-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
            {users.map(user => (
              <div key={user.id} className="bg-white p-6 rounded-xl border border-gray-200 hover:border-[#891F12] transition-all duration-200">
                {/* User Header */}
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="text-lg font-medium">
                      {user.firstName} {user.lastName}
                    </h3>
                    <p className="text-sm text-gray-500">{user.email}</p>
                    <p className="text-xs text-gray-400">
                      Member since {new Date(user.joinDate).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="text-sm text-gray-500">Total Investment Value</p>
                    <p className="text-lg font-semibold text-[#891F12]">
                      {formatCurrency(
                        user.investments.reduce((sum, inv) => sum + inv.currentValue, 0)
                      )}
                    </p>
                  </div>
                </div>

                {/* Investments */}
                <div className="space-y-3">
                  {user.investments.map(investment => (
                    <div key={investment.id} className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                      <div className="flex justify-between items-start">
                        <div>
                          <p className="font-medium">{investment.packageName}</p>
                          <p className="text-sm text-gray-500">
                            Initial: {formatCurrency(investment.amount)}
                          </p>
                          <p className="text-xs text-gray-400">
                            Updated: {new Date(investment.lastUpdated).toLocaleDateString()}
                          </p>
                        </div>
                        <div className="text-right">
                          <p className="font-medium text-[#891F12]">
                            {formatCurrency(investment.currentValue)}
                          </p>
                          <p className="text-sm text-green-600">
                            +{((investment.currentValue - investment.amount) / investment.amount * 100).toFixed(1)}%
                          </p>
                          <button className="text-sm text-[#891F12] hover:text-[#761A0F] mt-2">
                            Update Value
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {showCreateUser && (
        <CreateUserForm
          onSuccess={handleCreateSuccess}
          onError={handleCreateError}
          onClose={() => setShowCreateUser(false)}
        />
      )}
    </div>
  );
};

const StatCard = ({ title, value, icon: Icon, trend, color }) => (
  <div className="bg-white p-6 rounded-xl shadow-sm">
    <div className="flex justify-between">
      <div>
        <p className="text-sm text-gray-500">{title}</p>
        <h3 className="text-2xl font-semibold mt-1">{value}</h3>
        {trend && (
          <p className={`text-sm mt-1 ${trend >= 0 ? 'text-green-600' : 'text-red-600'}`}>
            {trend > 0 ? '↑' : '↓'} {Math.abs(trend)}%
          </p>
        )}
      </div>
      <div className={`p-3 rounded-full ${color} bg-opacity-10`}>
        <Icon className={`h-6 w-6 ${color}`} />
      </div>
    </div>
  </div>
);

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  }).format(amount);
};

export default PortfolioManagement; 