import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Navbar from './components/common/Navbar';
import Footer from './components/layout/Footer';

// Public Pages
import Home from './pages/Home';
import About from './pages/About';
import WhatWeDo from './pages/WhatWeDo';
import WhoWeAre from './pages/WhoWeAre';
import Events from './pages/Events';
import News from './pages/News';
import ArticlePage from './pages/ArticlePage';
import Contact from './pages/Contact';
import Investments from './pages/Investments';
import TeamProfile from './pages/TeamProfile';
import Gallery from './pages/Gallery';

// Auth Pages
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/auth/ProtectedRoute';

// Admin Pages
import AdminSetup from './pages/admin/AdminSetup';
import CreateAdmin from './pages/admin/CreateAdmin';
import AdminLogin from './pages/admin/AdminLogin';
import AdminDashboard from './pages/admin/AdminDashboard';
import AdminGuard from './components/guards/AdminGuard';

// Error Pages
import NotFound from './pages/NotFound';

import GlobalArtMarket2023 from './pages/articles/GlobalArtMarket2023';
import AfricanArtMarket from './pages/articles/AfricanArtMarket';

import './styles/globals.css';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <div className="app">
          <Navbar />
          <main className="min-h-screen pt-16">
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/what-we-do" element={<WhatWeDo />} />
              <Route path="/who-we-are" element={<WhoWeAre />} />
              <Route path="/events" element={<Events />} />
              <Route path="/news" element={<News />} />
              <Route path="/news/:articleId" element={<ArticlePage />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/investments" element={<Investments />} />
              <Route path="/gallery" element={<Gallery />} />
              
              {/* Team Profiles */}
              <Route path="/team/:id" element={<TeamProfile />} />
              
              {/* Auth Routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              
              {/* Protected User Routes */}
              <Route 
                path="/dashboard/*" 
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                } 
              />
              
              {/* Admin Routes */}
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route path="/admin/setup" element={<AdminSetup />} />
              <Route path="/create-admin" element={<CreateAdmin />} />
              <Route 
                path="/admin/dashboard/*" 
                element={
                  <AdminGuard>
                    <AdminDashboard />
                  </AdminGuard>
                } 
              />
              
              {/* Article Routes */}
              <Route path="/news/global-art-market-2023" element={<GlobalArtMarket2023 />} />
              <Route path="/news/african-art-market-growth" element={<AfricanArtMarket />} />
              
              {/* 404 Route */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
