import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../services/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { UserIcon, LockClosedIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';

function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      // Sign in with Firebase Auth
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      // Check if user is admin in Firestore
      const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
      const userData = userDoc.data();

      if (userData?.role !== 'admin') {
        // If not admin, sign out and show error
        await auth.signOut();
        setError('Unauthorized access. Admin only.');
        return;
      }

      // If admin, redirect to admin dashboard
      navigate('/admin/dashboard');
    } catch (error) {
      console.error('Login error:', error);
      setError('Failed to login. Please check your credentials.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#F8F7F4] py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-2xl shadow-lg">
        <div className="text-center">
          <div className="flex justify-center">
            <ShieldCheckIcon className="h-12 w-12 text-[#891F12]" />
          </div>
          <h2 className="mt-4 text-3xl font-bold text-[#2A0F0A]">
            Admin Portal
          </h2>
          <p className="mt-2 text-gray-600">
            Secure access for administrators
          </p>
        </div>

        {error && (
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
            {error}
          </div>
        )}

        <form className="mt-8 space-y-6" onSubmit={handleLogin}>
          <div className="space-y-4">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                <UserIcon className="h-5 w-5 text-[#891F12]" />
              </div>
              <input
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg 
                  focus:ring-[#891F12] focus:border-[#891F12] transition-colors"
                placeholder="Admin Email"
              />
            </div>

            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                <LockClosedIcon className="h-5 w-5 text-[#891F12]" />
              </div>
              <input
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg 
                  focus:ring-[#891F12] focus:border-[#891F12] transition-colors"
                placeholder="Password"
              />
            </div>
          </div>

          <button
            type="submit"
            disabled={loading}
            className="w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium 
              rounded-lg text-white bg-[#891F12] hover:bg-[#761A0F] 
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#891F12]
              transition-colors transform hover:scale-[1.02]"
          >
            {loading ? 'Signing in...' : 'Sign in to Admin Portal'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default AdminLogin; 