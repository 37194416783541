export const sampleInvestments = [
  {
    id: '1',
    name: 'Contemporary Art Collection A',
    type: 'Art Collection',
    currentValue: 250000,
    initialValue: 200000,
    currentYield: 12.5,
    lastUpdated: '2024-03-15',
    performance: 'up',
    pieces: 8,
    location: 'London Gallery',
    artist: 'Various Contemporary',
    riskLevel: 'Medium',
    status: 'Active'
  },
  {
    id: '2',
    name: 'Modern Masters Portfolio',
    type: 'Individual Pieces',
    currentValue: 475000,
    initialValue: 400000,
    currentYield: 18.75,
    lastUpdated: '2024-03-14',
    performance: 'up',
    pieces: 3,
    location: 'Secure Storage',
    artist: 'Mixed Modern',
    riskLevel: 'Low',
    status: 'Active'
  },
  // Add more sample investments...
];

export const analyticsData = {
  totalValue: 2750000,
  totalInvestments: 12,
  averageYield: 15.2,
  yearGrowth: 22.5,
  monthlyGrowth: 2.8,
  topPerformers: 4,
  riskProfile: {
    low: 35,
    medium: 45,
    high: 20
  },
  recentTransactions: [
    {
      id: 't1',
      type: 'Acquisition',
      value: 125000,
      date: '2024-03-10',
      description: 'New contemporary piece'
    },
    // Add more transactions...
  ]
};

export const sampleUsers = [
  {
    id: 'user1',
    firstName: 'James',
    lastName: 'Wilson',
    email: 'james.wilson@example.com',
    role: 'user',
    createdAt: '2024-02-15',
    investments: [
      {
        id: 'inv1',
        packageName: 'Professional',
        amount: 5000,
        currentValue: 5750,
        date: '2024-02-15',
        status: 'active',
        lastUpdated: '2024-03-15',
        history: [
          {
            date: '2024-02-15',
            value: 5000,
            type: 'initial investment'
          },
          {
            date: '2024-03-15',
            value: 5750,
            type: 'value update'
          }
        ]
      }
    ]
  },
  {
    id: 'user2',
    firstName: 'Sarah',
    lastName: 'Parker',
    email: 'sarah.parker@example.com',
    role: 'user',
    createdAt: '2024-01-20',
    investments: [
      {
        id: 'inv2',
        packageName: 'Enterprise',
        amount: 10000,
        currentValue: 12500,
        date: '2024-01-20',
        status: 'active',
        lastUpdated: '2024-03-15',
        history: [
          {
            date: '2024-01-20',
            value: 10000,
            type: 'initial investment'
          },
          {
            date: '2024-03-15',
            value: 12500,
            type: 'value update'
          }
        ]
      },
      {
        id: 'inv3',
        packageName: 'Professional',
        amount: 7500,
        currentValue: 8250,
        date: '2024-02-01',
        status: 'active',
        lastUpdated: '2024-03-15',
        history: [
          {
            date: '2024-02-01',
            value: 7500,
            type: 'initial investment'
          },
          {
            date: '2024-03-15',
            value: 8250,
            type: 'value update'
          }
        ]
      }
    ]
  },
  // Add more sample users...
]; 