import { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, getDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../../services/firebase';
import { 
  UserIcon, 
  EnvelopeIcon, 
  LockClosedIcon,
  CurrencyPoundIcon,
  BuildingLibraryIcon,
  CalendarIcon
} from '@heroicons/react/24/outline';

const INVESTMENT_PACKAGES = [
  { 
    name: 'Starter Art Collection',
    defaultValue: 5000,
    description: 'Entry-level art investment package',
    riskLevel: 'Low',
    features: ['Basic portfolio management', 'Quarterly updates', 'Email support']
  },
  { 
    name: 'Professional Portfolio',
    defaultValue: 15000,
    description: 'Advanced art investment features',
    riskLevel: 'Medium',
    features: ['Advanced portfolio tracking', 'Monthly updates', 'Priority support']
  },
  { 
    name: 'Premium Collection',
    defaultValue: 50000,
    description: 'Premium art investment package',
    riskLevel: 'High',
    features: ['Full portfolio management', 'Weekly updates', '24/7 dedicated support']
  }
];

function CreateUserForm({ onSuccess, onError, onClose }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    address: '',
    packageName: 'Starter Art Collection',
    initialInvestment: 5000,
    investmentDate: new Date().toISOString().split('T')[0],
    riskProfile: 'Low',
    investmentGoals: ''
  });

  const handlePackageChange = (packageName) => {
    const selectedPackage = INVESTMENT_PACKAGES.find(p => p.name === packageName);
    setFormData(prev => ({
      ...prev,
      packageName,
      initialInvestment: selectedPackage.defaultValue,
      riskProfile: selectedPackage.riskLevel
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Create auth account
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      // Prepare investment data
      const investmentData = {
        id: `inv-${Date.now()}`,
        packageName: formData.packageName,
        amount: parseFloat(formData.initialInvestment),
        currentValue: parseFloat(formData.initialInvestment), // Initial value same as investment
        date: formData.investmentDate,
        status: 'active',
        lastUpdated: serverTimestamp(),
        history: [
          {
            date: formData.investmentDate,
            type: 'initial investment',
            value: parseFloat(formData.initialInvestment)
          }
        ]
      };

      // Create user document
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        riskProfile: formData.riskProfile,
        investmentGoals: formData.investmentGoals,
        createdAt: serverTimestamp(),
        role: 'user',
        investments: [investmentData]
      });

      // Create activity log
      await addDoc(collection(db, 'activities'), {
        type: 'new_user',
        userId: userCredential.user.uid,
        userName: `${formData.firstName} ${formData.lastName}`,
        details: {
          packageName: formData.packageName,
          initialInvestment: parseFloat(formData.initialInvestment)
        },
        timestamp: serverTimestamp()
      });

      // Create welcome notification
      await addDoc(collection(db, 'notifications'), {
        userId: userCredential.user.uid,
        title: 'Welcome to Eagleton Art Investment',
        message: `Your ${formData.packageName} has been successfully set up.`,
        read: false,
        createdAt: serverTimestamp()
      });

      onSuccess?.();
      onClose?.();
    } catch (error) {
      console.error('Error creating user:', error);
      onError?.(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-2xl w-full p-6 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Create New Investor Account</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            ×
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Personal Information */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium">Personal Information</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">First Name</label>
                <input
                  type="text"
                  required
                  value={formData.firstName}
                  onChange={(e) => setFormData(prev => ({ ...prev, firstName: e.target.value }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#891F12] focus:ring-[#891F12]"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Last Name</label>
                <input
                  type="text"
                  required
                  value={formData.lastName}
                  onChange={(e) => setFormData(prev => ({ ...prev, lastName: e.target.value }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#891F12] focus:ring-[#891F12]"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  required
                  value={formData.email}
                  onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#891F12] focus:ring-[#891F12]"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Phone</label>
                <input
                  type="tel"
                  value={formData.phone}
                  onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#891F12] focus:ring-[#891F12]"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Password</label>
              <input
                type="password"
                required
                value={formData.password}
                onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#891F12] focus:ring-[#891F12]"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Address</label>
              <textarea
                value={formData.address}
                onChange={(e) => setFormData(prev => ({ ...prev, address: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#891F12] focus:ring-[#891F12]"
                rows="2"
              />
            </div>
          </div>

          {/* Investment Details */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium">Investment Details</h3>
            
            {/* Package Selection */}
            <div className="grid grid-cols-3 gap-4">
              {INVESTMENT_PACKAGES.map(pkg => (
                <div
                  key={pkg.name}
                  onClick={() => handlePackageChange(pkg.name)}
                  className={`cursor-pointer rounded-lg border p-4 ${
                    formData.packageName === pkg.name
                      ? 'border-[#891F12] bg-[#891F12]/5'
                      : 'border-gray-200 hover:border-gray-300'
                  }`}
                >
                  <div className="flex items-center justify-between mb-2">
                    <BuildingLibraryIcon className="h-6 w-6 text-[#891F12]" />
                    <div className="text-sm font-medium text-[#891F12]">
                      {new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP'
                      }).format(pkg.defaultValue)}
                    </div>
                  </div>
                  <h4 className="text-sm font-medium text-gray-900">{pkg.name}</h4>
                  <p className="text-sm text-gray-500 mt-1">{pkg.description}</p>
                  <ul className="mt-2 space-y-1">
                    {pkg.features.map((feature, index) => (
                      <li key={index} className="text-xs text-gray-500">• {feature}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Initial Investment</label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <CurrencyPoundIcon className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    required
                    min={0}
                    value={formData.initialInvestment}
                    onChange={(e) => setFormData(prev => ({ ...prev, initialInvestment: e.target.value }))}
                    className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#891F12] focus:ring-[#891F12]"
                  />
                </div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">Investment Date</label>
                <input
                  type="date"
                  required
                  value={formData.investmentDate}
                  onChange={(e) => setFormData(prev => ({ ...prev, investmentDate: e.target.value }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#891F12] focus:ring-[#891F12]"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Investment Goals</label>
              <textarea
                value={formData.investmentGoals}
                onChange={(e) => setFormData(prev => ({ ...prev, investmentGoals: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#891F12] focus:ring-[#891F12]"
                rows="2"
                placeholder="Long-term growth, regular income, etc."
              />
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:text-gray-900"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-[#891F12] text-white rounded-lg hover:bg-[#761A0F] disabled:opacity-50"
            >
              {loading ? 'Creating...' : 'Create Account'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateUserForm; 