import { useState, useEffect } from 'react';
import { 
  ArrowTrendingUpIcon, 
  ArrowTrendingDownIcon,
  CurrencyPoundIcon,
  ChartBarIcon,
  UserGroupIcon,
  ClockIcon
} from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { sampleInvestments, analyticsData } from '../../../utils/sampleData';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

const InvestmentControl = () => {
  const [investments] = useState(sampleInvestments);
  const [analytics] = useState(analyticsData);

  const StatCard = ({ title, value, icon: Icon, trend, color }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white p-6 rounded-xl shadow-sm border border-gray-100"
    >
      <div className="flex justify-between items-start">
        <div>
          <p className="text-sm text-gray-500">{title}</p>
          <h3 className="text-2xl font-semibold mt-1">{value}</h3>
          {trend && (
            <p className={`text-sm mt-1 ${trend > 0 ? 'text-green-600' : 'text-red-600'}`}>
              {trend > 0 ? '↑' : '↓'} {Math.abs(trend)}%
            </p>
          )}
        </div>
        <div className={`p-3 rounded-full ${color} bg-opacity-10`}>
          <Icon className={`h-6 w-6 ${color}`} />
        </div>
      </div>
    </motion.div>
  );

  return (
    <div className="p-4 sm:p-6 space-y-6">
      {/* Header Stats */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
        <StatCard
          title="Total Portfolio Value"
          value={new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP'
          }).format(analytics.totalValue)}
          icon={CurrencyPoundIcon}
          trend={analytics.yearGrowth}
          color="text-[#891F12]"
        />
        <StatCard
          title="Average Yield"
          value={`${analytics.averageYield}%`}
          icon={ChartBarIcon}
          trend={analytics.monthlyGrowth}
          color="text-[#D4C19C]"
        />
        <StatCard
          title="Active Investments"
          value={analytics.totalInvestments}
          icon={UserGroupIcon}
          color="text-[#761A0F]"
        />
        <StatCard
          title="Top Performers"
          value={analytics.topPerformers}
          icon={ArrowTrendingUpIcon}
          color="text-green-600"
        />
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
        <div className="bg-white p-4 sm:p-6 rounded-xl shadow-sm">
          <h3 className="text-lg font-medium mb-4">Portfolio Performance</h3>
          <div className="h-64 sm:h-80">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={[
                  { month: 'Jan', value: 2200000 },
                  { month: 'Feb', value: 2350000 },
                  { month: 'Mar', value: 2750000 }
                ]}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Area 
                  type="monotone" 
                  dataKey="value" 
                  stroke="#891F12" 
                  fill="#891F12" 
                  fillOpacity={0.1} 
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="bg-white p-4 sm:p-6 rounded-xl shadow-sm">
          <h3 className="text-lg font-medium mb-4">Recent Activity</h3>
          <div className="space-y-4 overflow-y-auto max-h-[400px]">
            {analytics.recentTransactions.map(transaction => (
              <div 
                key={transaction.id}
                className="flex items-center p-3 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <div className="p-2 rounded-full bg-[#891F12] bg-opacity-10 mr-4">
                  <ClockIcon className="h-5 w-5 text-[#891F12]" />
                </div>
                <div className="flex-1">
                  <p className="text-sm font-medium">{transaction.description}</p>
                  <p className="text-xs text-gray-500">{transaction.date}</p>
                </div>
                <div className="text-right">
                  <p className="text-sm font-medium">
                    {new Intl.NumberFormat('en-GB', {
                      style: 'currency',
                      currency: 'GBP'
                    }).format(transaction.value)}
                  </p>
                  <p className="text-xs text-gray-500">{transaction.type}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Investment Table */}
      <div className="bg-white rounded-xl shadow-sm">
        <div className="p-4 sm:p-6 border-b">
          <h3 className="text-lg font-medium">Active Investments</h3>
        </div>
        <div className="overflow-x-auto">
          {/* Existing table code */}
        </div>
      </div>
    </div>
  );
};

export default InvestmentControl; 